import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import {Utility} from 'app/common/utility';
import {promise} from "selenium-webdriver";
import {map} from "rxjs/operators";
import {Configuration} from "../../assets/general/configuration";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class Lookup {
  constructor(private http: Http) {
  }

  getLookUp(value: string, param1?: any, param2?: any, param3?: any ) {
    switch (value) {
      case 'billingproducts':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=billingproducts&business_id=' + param1).pipe(map(res => res.json()));
      case 'billingpaymentstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=billingpaymentstatus').pipe(map(res => res.json()));
      case 'warrantyinvoice':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=warrantyinvoice&business_id=' + param1).pipe(map(res => res.json()));
      case 'addresstypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=addresstypes').pipe(map(res => res.json()));
      case 'deadleadreasontypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=deadleadreasontypes').pipe(map(res => res.json()));
      case 'leadsources':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=leadsources').pipe(map(res => res.json()));
      case 'cities':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=cities').pipe(map(res => res.json()));
      case 'communicationtypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=communicationtypes&area_id=' + param1).pipe(map(res => res.json()));
      case 'businesses':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=businesses').pipe(map(res => res.json()));
      case 'questionnaireweightages':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=questionnaireweightages').pipe(map(res => res.json()));
      case 'users':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=users&business_id=' + param1).pipe(map(res => res.json()));
      case 'categories':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=categories').pipe(map(res => res.json()));
      case 'subcategories':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=subcategories').pipe(map(res => res.json()));
      case 'countries':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=countries').pipe(map(res => res.json()));
      case 'states':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=states').pipe(map(res => res.json()));
      case 'customercategories':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=customercategories').pipe(map(res => res.json()));
      case 'industries':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=industries').pipe(map(res => res.json()));
      case 'salesemployees':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=salesemployees&business_id=' + param1).pipe(map(res => res.json()));
      case 'designations':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=designations').pipe(map(res => res.json()));
      case 'statustypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=statustypes').pipe(map(res => res.json()));
      case 'salesorder':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=salesorder&business_id=' + param1).pipe(map(res => res.json()));
      case 'customertypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=customertypes').pipe(map(res => res.json()));
      case 'employees':
        if (param2 === 'Payroll Supervisor' && param3 !== null) {
          return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=employees&role=' + param1 + '&employee_id=' + param2 + '&business_id=' + param3).pipe(map(res => res.json()));
        } else {
          return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=employees').pipe(map(res => res.json()));
        }
      case 'documenttypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=documenttypes&area_id=' + param1).pipe(map(res => res.json()));
      case 'communicationtypeswithsystemlog':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=communicationtypeswithsystemlog').pipe(map(res => res.json()));
      case 'jobtitles':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=jobtitles').pipe(map(res => res.json()));
      case 'countrycode':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=countrycode').pipe(map(res => res.json()));
      case 'exporttypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=exporttypes&route=' + param1).pipe(map(res => res.json()));

      case 'logisticspaymentstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=logisticspaymentstatus').pipe(map(res => res.json()));
      case 'cashmemopaymentstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=cashmemopaymentstatus').pipe(map(res => res.json()));
      case 'purchasedorderpaymentstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=purchasedorderpaymentstatus').pipe(map(res => res.json()));
      case 'rawpurchasedorderpaymentstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=rawpurchasedorderpaymentstatus').pipe(map(res => res.json()));

      case 'vehicletypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=vehicletypes').pipe(map(res => res.json()));
      case 'transportationtypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=transportationtypes').pipe(map(res => res.json()));
      case 'transactionsubtype':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=transactionsubtype').pipe(map(res => res.json()));
      case 'currencies':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=currencies').pipe(map(res => res.json()));
      case 'chargetypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=chargetypes').pipe(map(res => res.json()));
      case 'modules':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=modules').pipe(map(res => res.json()));
      case 'expensestypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=expensestypes').pipe(map(res => res.json()));
      case 'bookingtypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=bookingtypes').pipe(map(res => res.json()));
      case 'planningstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=planningstatus').pipe(map(res => res.json()));
      case 'producttypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=producttypes').pipe(map(res => res.json()));
      case 'financialyears':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=financialyears').pipe(map(res => res.json()));
      case 'sizes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=sizes').pipe(map(res => res.json()));
      case 'materials':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=materials').pipe(map(res => res.json()));
      case 'daytypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=daytypes').pipe(map(res => res.json()));
      case 'leavetypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=leavetypes').pipe(map(res => res.json()));
      case 'scmordersources':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=scmordersources').pipe(map(res => res.json()));
      case 'scmorderstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=scmorderstatus').pipe(map(res => res.json()));
      case 'pricecategory':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=pricecategory').pipe(map(res => res.json()));
      case 'departments':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=departments').pipe(map(res => res.json()));
      case 'unit':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=unit').pipe(map(res => res.json()));
      case 'servicedeliverystatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=servicedeliverystatus').pipe(map(res => res.json()));
      case 'brand':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=brand').pipe(map(res => res.json()));
      case 'bonustypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=bonustypes').pipe(map(res => res.json()));
      case 'year':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=year').pipe(map(res => res.json()));
      case 'months':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=months').pipe(map(res => res.json()));
      case 'shift':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=shift').pipe(map(res => res.json()));
      case 'status':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=status').pipe(map(res => res.json()));
      case 'employeeleavestatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=employeeleavestatus').pipe(map(res => res.json()));
      case 'employeeswitworksite':
        if (param2 === 'Payroll Supervisor' && param3 !== null) {
          return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=employeeswitworksite&role=' + param1 + '&employee_id=' + param2).pipe(map(res => res.json()));
        } else {
          return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=employeeswitworksite').pipe(map(res => res.json()));
        }
      case 'customers':
        if (param2 !== 'Admin' && param3 !== null && param2 !== undefined && param3 !== undefined) {
          return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=customers&business_id=' + param1 + '&role=' + param2 + '&employee_id=' + param3).pipe(map(res => res.json()));
        } else {
          return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=customers&business_id=' + param1).pipe(map(res => res.json()));
        }
      case 'invoices':
        if (param2 !== 'Admin' && param3 !== null && param2 !== undefined && param3 !== undefined) {
          return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=invoices&business_id=' + param1 + '&role=' + param2 + '&employee_id=' + param3).pipe(map(res => res.json()));
        } else {
          return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=invoices&business_id=' + param1).pipe(map(res => res.json()));
        }
      case 'arrearstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=arrearstatus').pipe(map(res => res.json()));
      case 'incentivestatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=incentivestatus').pipe(map(res => res.json()));
      case 'clients':
        if (param2 !== 'Admin' && param3 !== null && param2 !== undefined && param3 !== undefined) {
          return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=clients&business_id=' + param1 + '&role=' + param2 + '&employee_id=' + param3).pipe(map(res => res.json()));
        } else {
          return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=clients&business_id=' + param1).pipe(map(res => res.json()));
        }
      case 'leadprospects':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=leadprospects').pipe(map(res => res.json()));
      case 'clientswithcompanyname':
        if (param2 !== 'Admin' && param3 !== null && param2 !== undefined && param3 !== undefined) {
          return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=clientswithcompanyname&business_id=' + param1 + '&role=' + param2 + '&employee_id=' + param3).pipe(map(res => res.json()));
        } else {
          return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=clientswithcompanyname&business_id=' + param1).pipe(map(res => res.json()));
        }
      case 'products':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=products&business_id=' + param1).pipe(map(res => res.json()));
      case 'paymentcycle':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=paymentcycle').pipe(map(res => res.json()));
      case 'loanstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=loanstatus').pipe(map(res => res.json()));
      case 'loanschedulestatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=loanschedulestatus').pipe(map(res => res.json()));
      case 'amcstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=amcstatus').pipe(map(res => res.json()));
      case 'installationproviders':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=installationproviders').pipe(map(res => res.json()));
      case 'amctypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=amctypes').pipe(map(res => res.json()));
      case 'jobopenings':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=jobopening').pipe(map(res => res.json()));
      case 'kpis':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=kpis').pipe(map(res => res.json()));
      case 'kras':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=kras').pipe(map(res => res.json()));
      case 'roles':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=roles').pipe(map(res => res.json()));
      case 'servicetypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=servicetypes').pipe(map(res => res.json()));
      case 'departmentwiseroles':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=departmentwiseroles').pipe(map(res => res.json()));
      case 'quotations':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=quotations&business_id=' + param1).pipe(map(res => res.json()));
      case 'trainings':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=trainings').pipe(map(res => res.json()));
      case 'accessareawisedepartments':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=accessareawisedepartments').pipe(map(res => res.json()));
      case 'kpibydepartmentidandroleid':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=kpibydepartmentidandroleid').pipe(map(res => res.json()));
      case 'kpibyemployeeid':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=kpibyemployeeid').pipe(map(res => res.json()));
      case 'adjustmenttype':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=adjustmenttype').pipe(map(res => res.json()));
      case 'stocktransferreasontype':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=stocktransferreasontype').pipe(map(res => res.json()));
      case 'quotationstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=quotationstatus').pipe(map(res => res.json()));
      case 'companygoaltitle':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=companygoaltitle').pipe(map(res => res.json()));
      case 'orientations':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=orientations').pipe(map(res => res.json()));
      case 'projects':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=projects').pipe(map(res => res.json()));
      case 'tasks':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=tasks').pipe(map(res => res.json()));
      case 'recruitmentstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=recruitmentstatus').pipe(map(res => res.json()));
      case 'complaintstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=complaintstatus').pipe(map(res => res.json()));
      case 'complainttypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=complainttypes').pipe(map(res => res.json()));
      case 'addresses':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=addresses&customer_id=' + param1).pipe(map(res => res.json()));
      case 'questions':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=questions').pipe(map(res => res.json()));
      case 'hrdepartmentgoal':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=hrdepartmentgoal').pipe(map(res => res.json()));
      case 'recruitmentcurrentstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=recruitmentcurrentstatus').pipe(map(res => res.json()));
      case 'jobworkassignmenthistory':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=jobworkassignmenthistory').pipe(map(res => res.json()));
      case 'jobworkstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=jobworkstatus').pipe(map(res => res.json()));
      case 'suppliertypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=suppliertypes').pipe(map(res => res.json()));
      case 'getstatecountrywise':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=getstatecountrywise').pipe(map(res => res.json()));
      case 'jobstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=jobstatus').pipe(map(res => res.json()));
      case 'getcitystatewise':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=getcitystatewise').pipe(map(res => res.json()));
      case 'getdepartmentwisejob':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=getdepartmentwisejob').pipe(map(res => res.json()));
      case 'mcqweightages':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=mcqweightages').pipe(map(res => res.json()));
      case 'communicationmodes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=communicationmodes').pipe(map(res => res.json()));
      case 'crmtemplates':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=crmtemplates').pipe(map(res => res.json()));
      case 'payrolltemplates':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=payrolltemplates').pipe(map(res => res.json()));
      case 'hrtemplates':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=hrtemplates').pipe(map(res => res.json()));
      case 'scmtemplates':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=scmtemplates').pipe(map(res => res.json()));
      case 'salestemplates':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=salestemplates').pipe(map(res => res.json()));
      case 'projecttemplates':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=projecttemplates').pipe(map(res => res.json()));
      case 'templatetypesemail':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=templatetypesemail').pipe(map(res => res.json()));
      case 'hours':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=hours').pipe(map(res => res.json()));
      case 'templatetypessms':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=templatetypessms').pipe(map(res => res.json()));
      case 'templatetypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=templatetypes').pipe(map(res => res.json()));
      case 'getamcclientwise':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=getamcclientwise').pipe(map(res => res.json()));
      case 'skilledcategory':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=skilledcategory').pipe(map(res => res.json()));
      case 'employeetypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=employeetypes').pipe(map(res => res.json()));
      case 'amc':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=amc').pipe(map(res => res.json()));
      case 'feedbackweightages':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=feedbackweightages').pipe(map(res => res.json()));
      case 'controltypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=controltypes').pipe(map(res => res.json()));
      case 'bloodgroups':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=bloodgroups').pipe(map(res => res.json()));
      case 'vendors':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=vendors').pipe(map(res => res.json()));
      case 'boilersuitsizes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=boilersuitsizes').pipe(map(res => res.json()));
      case 'safetyshoessizes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=safetyshoessizes').pipe(map(res => res.json()));
      case 'payouttypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=payouttypes').pipe(map(res => res.json()));
      case 'payrollstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=payrollstatus').pipe(map(res => res.json()));
      case 'worksites':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=worksites&business_id=' + param1).pipe(map(res => res.json()));
      case 'managers':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=managers&business_id=' + param1).pipe(map(res => res.json()));
      case 'worksitesmanagers':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=worksitesmanagers').pipe(map(res => res.json()));
      case 'paymentmodes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=paymentmodes').pipe(map(res => res.json()));
      case 'travelexpensestatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=travelexpensestatus').pipe(map(res => res.json()));
      case 'attended':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=attended').pipe(map(res => res.json()));
      case 'servicecycle':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=servicecycle').pipe(map(res => res.json()));
      case 'servicestatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=servicestatus').pipe(map(res => res.json()));
      case 'days':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=days').pipe(map(res => res.json()));
      case 'complaintemployee':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=complaintemployee').pipe(map(res => res.json()));
      case 'salesorderstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=salesorderstatus').pipe(map(res => res.json()));
      case 'invoicestatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=invoicestatus').pipe(map(res => res.json()));
      case 'billingstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=billingstatus').pipe(map(res => res.json()));
      case 'warrantyinvoices':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=warrantyinvoices&business_id=' + param1).pipe(map(res => res.json()));
      case 'invoicepaymentstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=invoicepaymentstatus').pipe(map(res => res.json()));
      case 'servicememopaymentstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=servicememopaymentstatus').pipe(map(res => res.json()));
      case 'rawmaterials':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=rawmaterials').pipe(map(res => res.json()));
      case 'suppliers':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=suppliers&business_id=' + param1).pipe(map(res => res.json()));
      case 'productinventory':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=productinventory&business_id=' + param1).pipe(map(res => res.json()));
      case 'purchaseorderstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=purchaseorderstatus').pipe(map(res => res.json()));
      case 'warehouses':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=warehouses&business_id=' + param1).pipe(map(res => res.json()));
      case 'documentreferencetypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=documentreferencetypes').pipe(map(res => res.json()));
      case 'partnerstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=partnerstatus').pipe(map(res => res.json()));
      case 'partners':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=partners').pipe(map(res => res.json()));
      case 'partnercommunicationtypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=partnercommunicationtypes').pipe(map(res => res.json()));
      case 'vendorcommunicationtypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=vendorcommunicationtypes').pipe(map(res => res.json()));
      case 'candidatecommunicationtypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=candidatecommunicationtypes').pipe(map(res => res.json()));
      case 'degrees':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=degrees').pipe(map(res => res.json()));
      case 'businessclient':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=businessclient').pipe(map(res => res.json()));
      case 'skills':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=skills').pipe(map(res => res.json()));
      case 'suppliercommunicationtypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=suppliercommunicationtypes').pipe(map(res => res.json()));
      case 'jobopeningcommunicationtypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=jobopeningcommunicationtypes').pipe(map(res => res.json()));
      case 'interviewtypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=interviewtypes').pipe(map(res => res.json()));
      case 'candidates':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=candidates').pipe(map(res => res.json()));
      case 'partnertypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=partnertypes').pipe(map(res => res.json()));
      case 'questionnairetypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=questionnairetypes').pipe(map(res => res.json()));
      case 'relationshiptypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=relationshiptypes').pipe(map(res => res.json()));
      case 'assettypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=assettypes').pipe(map(res => res.json()));
      case 'docket':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=docket').pipe(map(res => res.json()));
      case 'claimstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=claimstatus').pipe(map(res => res.json()));
      case 'paymentstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=paymentstatus').pipe(map(res => res.json()));
      case 'enquirytypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=enquirytypes').pipe(map(res => res.json()));
      case 'projecttypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=projecttypes').pipe(map(res => res.json()));
      case 'enquirystatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=enquirystatus').pipe(map(res => res.json()));
      case 'actualvisitcommunicationtypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=actualvisitcommunicationtypes').pipe(map(res => res.json()));
      case 'tendertypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=tendertypes').pipe(map(res => res.json()));
      case 'tenderstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=tenderstatus').pipe(map(res => res.json()));
      case 'projectstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=projectstatus').pipe(map(res => res.json()));
      case 'enquiries':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=enquiries').pipe(map(res => res.json()));
      case 'tendercommunicationtypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=tendercommunicationtypes').pipe(map(res => res.json()));
      case 'registrationstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=registrationstatus').pipe(map(res => res.json()));
      case 'technicalstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=technicalstatus').pipe(map(res => res.json()));
      case 'quotationpriority':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=quotationpriority').pipe(map(res => res.json()));
      case 'references':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=references').pipe(map(res => res.json()));
      case 'complaintpaymentstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=complaintpaymentstatus').pipe(map(res => res.json()));
      case 'quotationdetails':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=quotationdetails').pipe(map(res => res.json()));
      case 'gstslabs':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=gstslabs').pipe(map(res => res.json()));
      case 'jobworkpaymentstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=jobworkpaymentstatus').pipe(map(res => res.json()));
      case 'jobschedulingstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=jobschedulingstatus').pipe(map( res => res.json()));
      case 'invoicereference':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=invoicereference&business_id=' + param1).pipe(map(res => res.json()));
      case 'customerandpartner':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=customerandpartner').pipe(map(res => res.json()));
      case 'areas':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=areas&module_id=' + param1).pipe(map(res => res.json()));
      case 'permissions':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=permissions').pipe(map(res => res.json()));
      case 'measurementtypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=measurementtypes').pipe(map(res => res.json()));
      case 'paymentslipstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=paymentslipstatus').pipe(map(res => res.json()));
      case 'usertype':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=usertype').pipe(map(res => res.json()));
      case 'prospects':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=prospects&role=' + param1 + '&employee_id=' + param2).pipe(map(res => res.json()));
      case 'referencewithsalesorder':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=referencewithsalesorder&business_id=' + param1).pipe(map(res => res.json()));
      case 'inventorystatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=inventorystatus').pipe(map(res => res.json()));
      case 'invoicetypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=invoicetypes').pipe(map(res => res.json()));
      case 'financetemplates':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=financetemplates').pipe(map(res => res.json()));
      case 'challandeliverymodes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=challandeliverymodes').pipe(map(res => res.json()));
      case 'issuestatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=issuestatus').pipe(map(res => res.json()));
      case 'accountgroups':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=accountgroups').pipe(map(res => res.json()));
      case 'accounts':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=accounts').pipe(map(res => res.json()));
      case 'procurementstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=procurementstatus').pipe(map(res => res.json()));
      case 'costcards':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=costcards').pipe(map(res => res.json()));
      case 'purchaseorderno':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=purchaseorderno').pipe(map(res => res.json()));
      case 'purchasedorder':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=purchasedorder&business_id=' + param1).pipe(map(res => res.json()));
      case 'productinventorystockinno':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=productinventorystockinno').pipe(map(res => res.json()));
      case 'rawmaterialsorderno':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=rawmaterialsorderno').pipe(map(res => res.json()));
      case 'payslipstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=payslipstatus').pipe(map(res => res.json()));
      case 'contractstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=contractstatus').pipe(map(res => res.json()));
      case 'creditnotestatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=creditnotestatus').pipe(map(res => res.json()));
      case 'creditnoteinvoices':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=creditnoteinvoices').pipe(map(res => res.json()));
      case 'purchaseorders':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=purchaseorders').pipe(map(res => res.json()));
      case 'commissioninvoices':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=commissioninvoices').pipe(map(res => res.json()));
      case 'productionplanningworkflowstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=productionplanningworkflowstatus').pipe(map(res => res.json()));
      case 'orderworkflowstatus':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=orderworkflowstatus').pipe(map(res => res.json()));
      case 'referencewithemployee':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=referencewithemployee').pipe(map(res => res.json()));
      case 'paymentoptions':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=paymentoptions').pipe(map(res => res.json()));

      case 'socialmediatypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=socialmediatypes').pipe(map(res => res.json()));
      case 'contacttypes':
        return this.http.get(Configuration.serviceCoreURL + '/getlookup?lookuptype=contacttypes').pipe(map(res => res.json()));

    }

  }
}
