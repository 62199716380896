import {NgModule, ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LayoutComponent} from './shared/layout/layout.component';
import {LoginComponent} from "./login/login.component";
import {RedirectComponent} from "@app/redirect/redirect.component";
import {SidemenulayoutComponent} from './shared/sidemenulayout/sidemenulayout.component';
import {CancelledserialnumberlistComponent} from "./sales/cancelledserialnumber/cancelledserialnumberlist/cancelledserialnumberlist.component";
import {CancelledserialnumberviewComponent} from "./sales/cancelledserialnumber/cancelledserialnumberview/cancelledserialnumberview.component";
import {InsertcancelledserialnumberComponent} from "./sales/cancelledserialnumber/insertcancelledserialnumber/insertcancelledserialnumber.component";
import {UpdatecancelledserialnumberComponent} from "./sales/cancelledserialnumber/updatecancelledserialnumber/updatecancelledserialnumber.component";
import {AuthGuard} from './auth.guard';
import {InsertsalaryComponent} from './finance/costing/salary/insertsalary/insertsalary.component';
import {UpdatesalaryComponent} from './finance/costing/salary/updatesalary/updatesalary.component';
import {SalaryviewComponent} from './finance/costing/salary/salaryview/salaryview.component';
import {PagenotfoundComponent} from "@app/errors/pagenotfound/pagenotfound.component";
import {NoaccessComponent} from './errors/noaccess/noaccess.component';
import {AccountComponent} from "@app/account/account/account.component";
import {ChangepasswordComponent} from "@app/account/changepassword/changepassword.component";
import {UsersComponent} from "@app/account/users/users.component";




const appRoutes: Routes = [
    {path: '', component: RedirectComponent},
    {path: 'login', component: LoginComponent},

    {
        path: '',
        component: LayoutComponent,
        children: [

          {path: 'decisionscienceanalytics', loadChildren: './management/analytics/analytics.module#AnalyticsModule'},
          {path: 'objectives', loadChildren: './management/objectives/objectives.module#ObjectivesModule'},
          {path: 'recommendation', loadChildren: './management/recommendation/recommendation.module#RecommendationModule'},
          {path: 'boardreview', loadChildren: './management/boardreview/boardreview.module#BoardreviewModule'},
          {path: 'jobscheduling', loadChildren: './scm/jobscheduling/jobschedulinglist/jobschedulinglist.module#JobschedulinglistModule'},
          {path: 'insertjobscheduling', loadChildren: './scm/jobscheduling/insertjobscheduling/insertjobscheduling.module#InsertjobschedulingModule'},
          {path: 'updatejobscheduling/:id', loadChildren: './scm/jobscheduling/updatejobscheduling/updatejobscheduling.module#UpdatejobschedulingModule'},
          {path: 'jobschedulingview/:id', loadChildren: './scm/jobscheduling/jobschedulingview/jobschedulingview.module#JobschedulingviewModule'},
          {path: 'insertlogistics', loadChildren: './scm/order/insertlogistics/insertlogistics.module#InsertlogisticsModule'},

          {path: 'servicequotation', loadChildren: './sales/service/servicequotation/servicequotationlist/servicequotation.module#ServicequotationModule'},
          {path: 'servicequotationview/:id', loadChildren: './sales/service/servicequotation/servicequotationview/servicequotationview.module#ServicequotationviewModule'},
          {path: 'insertservicequotation', loadChildren: './sales/service/servicequotation/insertservicequotation/insertservicequotation.module#InsertservicequotationModule'},
          {path: 'updateservicequotation/:id', loadChildren: './sales/service/servicequotation/updateservicequotation/updateservicequotation.module#UpdateservicequotationModule'},
          {path: 'cloneservicequotation/:id', loadChildren: './sales/service/servicequotation/cloneservicequotation/cloneservicequotation.module#CloneservicequotationModule'},

          {path: 'serviceinvoice', loadChildren: './sales/service/serviceinvoice/serviceinvoicelist/serviceinvoicelist.module#ServiceinvoicelistModule'},
          {path: 'insertserviceinvoice', loadChildren: './sales/service/serviceinvoice/insertserviceinvoice/insertserviceinvoice.module#InsertserviceinvoiceModule'},
          {path: 'updateserviceinvoice/:id', loadChildren: './sales/service/serviceinvoice/updateserviceinvoice/updateserviceinvoice.module#UpdateserviceinvoiceModule'},
          {path: 'serviceinvoiceview/:id', loadChildren: './sales/service/serviceinvoice/serviceinvoiceview/serviceinvoiceview.module#ServiceinvoiceviewModule'},

          {path: 'servicememo', loadChildren: './sales/service/servicememomanagement/servicememolist/servicememolist.module#ServicememolistModule'},
          {path: 'insertservicememo', loadChildren: './sales/service/servicememomanagement/insertservicememo/insertservicememo.module#InsertservicememoModule'},
          {path: 'updateservicememo/:id', loadChildren: './sales/service/servicememomanagement/updateservicememo/updateservicememo.module#UpdateservicememoModule'},
          {path: 'servicememoview/:id', loadChildren: './sales/service/servicememomanagement/servicememoview/servicememoview.module#ServicememoviewModule'},

          {path: 'servicedelivery', loadChildren: './sales/service/servicedelivery/servicedeliverylist/servicedelivery.module#ServicedeliveryModule'},
          {path: 'insertservicedelivery', loadChildren: './sales/service/servicedelivery/insertservicedelivery/insertservicedelivery.module#InsertservicedeliveryModule'},
          {path: 'updateservicedelivery/:id', loadChildren: './sales/service/servicedelivery/updateservicedelivery/updateservicedelivery.module#UpdateservicedeliveryModule'},
          {path: 'servicedeliveryview/:id', loadChildren: './sales/service/servicedelivery/servicedeliveryview/servicedeliveryview.module#ServicedeliveryviewModule'},

          {path: 'ewaybill', loadChildren: './sales/ewaybill/ewaybilllist/ewaybilllist.module#EwaybilllistModule'},
          {path: 'insertewaybill', loadChildren: './sales/ewaybill/insertewaybill/insertewaybill.module#InsertewaybillModule'},
          {path: 'insertewaybill/:id', loadChildren: './sales/ewaybill/insertewaybill/insertewaybill.module#InsertewaybillModule'},
          {path: 'viewewaybill/:id', loadChildren: './sales/ewaybill/viewewaybill/viewewaybill.module#ViewewaybillModule'},
          {path: 'updateewaybill/:id', loadChildren: './sales/ewaybill/updateewaybill/updateewaybill.module#UpdateewaybillModule'},

          {path: 'billing', loadChildren: './pos/billing/billingslist/billinglist.module#BillinglistModule'},
          {path: 'billingview/:id', loadChildren: './pos/billing/billingview/billingview.module#BillingviewModule'},
          {path: 'insertbilling', loadChildren: './pos/billing/insertbilling/insertbilling.module#InsertbillingModule'},
          {path: 'updatebilling/:id', loadChildren: './pos/billing/updatebilling/updatebilling.module#UpdatebillingModule'},

          {path: 'noaccesspage', loadChildren: './errors/noaccess/noaccess.module#NoaccessModule'},
          {path: 'settings', loadChildren: './platformconfiguration/settings/settinglist/settinglist.module#SettinglistModule'},
          {path: 'updatesetting/:id', loadChildren: './platformconfiguration/settings/updatesetting/updatesetting.module#UpdatesettingModule'},
          {path: 'cancelledserialnumber', component: CancelledserialnumberlistComponent, canActivate: [AuthGuard]},
          {path: 'insertcancelledserialnumber', component: InsertcancelledserialnumberComponent, canActivate: [AuthGuard]},
          {path: 'updatecancelledserialnumber/:id', component: UpdatecancelledserialnumberComponent, canActivate: [AuthGuard]},
          {path: 'cancelledserialnumberview/:id', component: CancelledserialnumberviewComponent, canActivate: [AuthGuard]},

            {path: 'salesadmindashboard', loadChildren: './sales/salesadmindashboard/salesadmindashboard.module#SalesadmindashboardModule'},
            {path: 'salessummary', loadChildren: './sales/summary/summary.module#SummaryModule'},
            {path: 'franchisedashboard', loadChildren: './franchise/franchisedashboard/franchisedashboard.module#FranchisedashboardModule'},
            {path: 'leads', loadChildren: './sales/leads/leadslist/leadlist.module#LeadlistModule'},
            {path: 'insertlead', loadChildren: './sales/leads/insertlead/insertlead.module#InsertleadModule'},
            {path: 'updatelead/:id', loadChildren: './sales/leads/updatelead/updatelead.module#UpdateleadModule'},
            {path: 'leadview/:id', loadChildren: './sales/leads/leadview/leadview.module#LeadviewModule'},
            {path: 'exportlead', loadChildren: './sales/leads/exportlead/exportlead.module#ExportleadModule'},
            {path: 'importlead', loadChildren: './sales/leads/importleadlog/importleadlog.module#ImportleadlogModule'},
            {path: 'customers', loadChildren: './sales/customer/customerlist/customerlist.module#CustomerlistModule'},
            {path: 'customerview/:id', loadChildren: './sales/customer/customerview/customerview.module#CustomerviewModule'},
            {path: 'insertcustomer', loadChildren: './sales/customer/insertcustomer/insertcustomer.module#InsertcustomerModule'},
            {path: 'updatecustomer/:id', loadChildren: './sales/customer/updatecustomer/updatecustomer.module#UpdatecustomerModule'},
            {path: 'exportcustomer', loadChildren: './sales/customer/exportcustomer/exportcustomer.module#ExportcustomerModule'},
           {path: 'importcustomer', loadChildren: './sales/customer/importcustomerlog/importcustomerlog.module#ImportcustomerlogModule'},
            {path: 'contacts',  loadChildren: './sales/contacts/contactlist/contactlist.module#ContactlistModule'},
            {path: 'updatecontact/:id',  loadChildren: './sales/contacts/updatecontact/updatecontact.module#UpdatecontactModule'},
            {path: 'insertcontact',  loadChildren: './sales/contacts/insertcontact/insertcontact.module#InsertcontactModule'},
            {path: 'contactview/:id',  loadChildren: './sales/contacts/contactview/contactview.module#ContactviewModule'},
            {path: 'exportcontact', loadChildren: './sales/contacts/exportcontact/exportcontact.module#ExportcontactModule'},
            {path: 'importcontact', loadChildren: './sales/contacts/importcontact/importcontact.module#ImportcontactModule'},
            // {path: 'searchcustomers', loadChildren: './sales/serchcustomers/searchcustomers.module#SearchcustomersModule'},
            {path: 'brands', loadChildren: './masters/common/brand/brandlist/brands.module#BrandsModule'},
            {path: 'insertbrand', loadChildren: './masters/common/brand/insertbrand/insertbrand.module#InsertbrandModule'},
            {path: 'updatebrand/:id', loadChildren: './masters/common/brand/updatebrand/updatebrand.module#UpdatebrandModule'},
            {path: 'crmdashboard', loadChildren: './crm/crmdashboard/crmdashboard.module#CrmdashboardModule'},
            {path: 'crmsummary', loadChildren: './crm/summary/summary.module#SummaryModule'},
            {path: 'complaints', loadChildren: './crm/complaints/complaintlist/complaints.module#ComplaintsModule'},
            {path: 'complaintview/:id', loadChildren: './crm/complaints/complaintview/complaintview.module#ComplaintviewModule'},
             {path: 'insertjobwork', loadChildren: './crm/jobwork/insertjobwork/insertjobwork.module#InsertJobworkModule'},
             {path: 'insertjobwork/:id', loadChildren: './crm/jobwork/insertjobwork/insertjobwork.module#InsertJobworkModule'},
             {path: 'updatejobwork/:id', loadChildren: './crm/jobwork/updatejobwork/updatejobwork.module#UpdateJobworkModule'},
             {path: 'jobworkview/:id', loadChildren: './crm/jobwork/jobworkview/jobworkview.module#JobworkviewModule'},
             {path: 'jobworks', loadChildren: './crm/jobwork/jobworklist/jobworklist.module#JobworksModule'},
             {path: 'exportjobwork', loadChildren: './crm/jobwork/exportjobwork/exportjobwork.module#ExportjobworkModule'},
            {path: 'insertamc', loadChildren: './crm/amc/insertamc/insertamc.module#InsertAmcModule'},
            {path: 'updateamc/:id', loadChildren: './crm/amc/updateamc/updateamc.module#UpdateamcModule'},
            {path: 'amcview/:id', loadChildren: './crm/amc/amcview/amcview.module#AmcviewModule'},
            {path: 'amc', loadChildren: './crm/amc/amclist/amclist.module#AmcModule'},
            {path: 'amcrenewal/:id', loadChildren: './crm/amc/amcrenewal/amcrenewal.module#AmcrenewalModule'},
            {path: 'importamc', loadChildren: './crm/amc/importamclog/importamc.module#ImportamcModule'},
            {path: 'searchcustomers', loadChildren: './crm/serchcustomers/searchcustomers.module#SearchcustomersModule'},
            {path: 'insertcomplaint/:id', loadChildren: './crm/complaints/insertcomplaint/insertcomplaint.module#InsertcomplaintModule'},
            {path: 'updatecomplaint/:id', loadChildren: './crm/complaints/updatecomplaint/updatecomplaint.module#UpdatecomplaintModule'},
            {path: 'exportcomplaint', loadChildren: './crm/complaints/exportcomplaint/exportcomplaint.module#ExportcomplaintModule'},
            {path: 'viewfeedback/:id', loadChildren: './crm/complaints/viewfeedback/viewfeedback.module#ViewfeedbackModule'},
           {path: 'calendar', loadChildren: './calendar/calendar/calendar.module#CalendarModule'},
          {path: 'pipelines', loadChildren: './sales/pipeline/pipeline/pipeline.module#PipelineModule'},
          {path: 'quotations', loadChildren: './sales/quotation/quotationlist/quotationlist.module#QuotationlistModule'},
          {path: 'quotationview/:id', loadChildren: './sales/quotation/quotationview/quotationview.module#QuotationviewModule'},
          {path: 'insertquotation', loadChildren: './sales/quotation/insertquotation/insertquotation.module#InsertquotationModule'},
          {path: 'updatequotation/:id', loadChildren: './sales/quotation/updatequotation/updatequotation.module#UpdatequotationModule'},
          {path: 'clonequotation/:id', loadChildren: './sales/quotation/clonequotation/clonequotation.module#ClonequotationModule'},
          {path: 'warranty', loadChildren: './sales/warranty/warrantylist/warrantylist.module#WarrantylistModule'},
          {path: 'insertwarranty', loadChildren: './sales/warranty/insertwarranty/insertwarranty.module#InsertwarrantyModule'},
          {path: 'warrantyview/:id', loadChildren: './sales/warranty/warrantyview/warrantyview.module#WarrantyviewModule'},
          {path: 'updatewarranty/:id', loadChildren: './sales/warranty/updatewarranty/updatewarranty.module#UpdatewarrantyModule'},
          {path: 'billofquantities', loadChildren: './sales/billofquantities/billofquantitieslist/billofquantitieslist.module#BillofquantitieslistModule'},
          {path: 'insertbillofquantities', loadChildren: './sales/billofquantities/insertbillofquantities/insertbillofquantities.module#InsertbillofquantitiesModule'},
          {path: 'updatebillofquantities/:id', loadChildren: './sales/billofquantities/updatebillofquantities/updatebillofquantities.module#UpdatebillofquantitiesModule'},
          {path: 'billofquantitiesview/:id', loadChildren: './sales/billofquantities/billofquantitiesview/billofquantitiesview.module#BillofquantitiesviewModule'},
          {path: 'clonebillofquantities/:id', loadChildren: './sales/billofquantities/clonebillofquantities/clonebillofquantities.module#ClonebillofquantitiesModule'},
          {path: 'salesorders', loadChildren: './sales/salesorder/salesorderlist/salesorderlist.module#SalesorderlistModule'},
          {path: 'insertsalesorder', loadChildren: './sales/salesorder/insertsalesorder/insertsalesorder.module#InsertsalesorderModule'},
          {path: 'updatesalesorder/:id', loadChildren: './sales/salesorder/updatesalesorder/updatesalesorder.module#UpdatesalesorderModule'},
          {path: 'salesorderview/:id', loadChildren: './sales/salesorder/salesorderview/salesorderview.module#SalesorderviewModule'},
          {path: 'insertpayment', loadChildren: './finance/payment/insertpayment/insertpayment.module#InsertpaymentModule'},
          {path: 'insertpaymentout', loadChildren: './finance/payment/insertpaymentout/insertpaymentout.module#InsertpaymentoutModule'},
          {path: 'insertpayment/:id', loadChildren: './finance/payment/insertpayment/insertpayment.module#InsertpaymentModule'},
          {path: 'updatepayment/:id', loadChildren: './finance/payment/updatepayment/updatepayment.module#UpdatepaymentModule'},
          {path: 'updatepaymentout/:id', loadChildren: './finance/payment/updatepaymentout/updatepaymentout.module#UpdatepaymentoutModule'},
          {path: 'payments', loadChildren: './finance/payment/paymentlist/paymentlist.module#PaymentlistModule'},
          {path: 'paymentview/:id', loadChildren: './finance/payment/paymentview/paymentview.module#PaymentviewModule'},
          {path: 'importpayment', loadChildren: './finance/payment/importpayment/importpayment.module#ImportpaymentModule'},
          {path: 'updatedeliverychallan/:id', loadChildren: './sales/deliverychallan/updatedeliverychallan/updatedeliverychallan.module#UpdatedeliverychallanModule'},
          {path: 'viewconsignmentchallan/:id', loadChildren: './sales/consignmentchallan/viewconsignmentchallan/viewconsignmentchallan.module#ViewconsignmentchallanModule'},
          {path: 'updateconsignmentchallan/:id', loadChildren: './sales/consignmentchallan/updateconsignmentchallan/updateconsignmentchallan.module#UpdateconsignmentchallanModule'},
          {path: 'viewdeliverychallan/:id', loadChildren: './sales/deliverychallan/viewdeliverychallan/viewdeliverychallan.module#ViewdeliverychallanModule'},
          {path: 'insertconsignmentchallan', loadChildren: './sales/consignmentchallan/insertconsignmentchallan/insertconsignmentchallan.module#InsertconsignmentchallanModule'},
          {path: 'insertdeliverychallan', loadChildren: './sales/deliverychallan/insertdeliverychallan/insertdeliverychallan.module#InsertdeliverychallanModule'},
          {path: 'deliverychallan', loadChildren: './sales/deliverychallan/deliverychallanlist/deliverychallanlist.module#DeliverychallanlistModule'},
          {path: 'consignmentchallan', loadChildren: './sales/consignmentchallan/consignmentchallanlist/consignmentchallanlist.module#ConsignmentchallanlistModule'},
          {path: 'invoices', loadChildren: './sales/invoice/invoicelist/invoicelist.module#InvoicelistModule'},
          {path: 'insertinvoice', loadChildren: './sales/invoice/insertinvoice/insertinvoice.module#InsertinvoiceModule'},
          {path: 'updateinvoice/:id', loadChildren: './sales/invoice/updateinvoice/updateinvoice.module#UpdateinvoiceModule'},
          {path: 'invoiceview/:id', loadChildren: './sales/invoice/invoiceview/invoiceview.module#InvoiceviewModule'},
          {path: 'cashmemo', loadChildren: './sales/cashmemo/cashmemolist/cashmemolist.module#CashmemolistModule'},
          {path: 'cashmemoview/:id', loadChildren: './sales/cashmemo/cashmemoview/cashmemoview.module#CashmemoviewModule'},
          {path: 'insertcashmemo', loadChildren: './sales/cashmemo/insertcashmemo/insertcashmemo.module#InsertcashmemoModule'},
          {path: 'updatecahsmemo/:id', loadChildren: './sales/cashmemo/updatecashmemo/updatecashmemo.module#UpdatecashmemoModule'},
          {path: 'actualvisits', loadChildren: './businessconsulting/actualvisits/actualvisitlist/actualvisitlist.module#ActualvisitlistModule'},
          {path: 'insertactualvisit', loadChildren: './businessconsulting/actualvisits/insertactualvisit/insertactualvisit.module#InsertactualvisitModule'},
          {path: 'updateactualvisit/:id', loadChildren: './businessconsulting/actualvisits/updateactualvisit/updateactualvisit.module#UpdateactualvisitModule'},
          {path: 'viewactualvisit/:id', loadChildren: './businessconsulting/actualvisits/viewactualvisit/viewactualvisit.module#ViewactualvisitModule'},
          {path: 'insertbooking',  loadChildren: './businessconsulting/booking/insertbooking/insertbooking.module#InsertbookingModule'},
          {path: 'updatebooking/:id', loadChildren: './businessconsulting/booking/updatebooking/updatebooking.module#UpdatebookingModule'},
          {path: 'bookingview/:id', loadChildren: './businessconsulting/booking/bookingview/bookingview.module#BookingviewModule'},
          {path: 'booking', loadChildren: './businessconsulting/booking/bookinglist/bookinglist.module#BookinglistModule'},
          {path: 'businessplan', loadChildren: './businessconsulting/businessplan/businessplanlist/businessplanlist.module#BusinessplanlistModule'},
          {path: 'insertbusinessplan', loadChildren: './businessconsulting/businessplan/insertbusinessplan/insertbusinessplan.module#InsertbusinessplanModule'},
          {path: 'updatebusinessplan/:id', loadChildren: './businessconsulting/businessplan/updatebusinessplan/updatebusinessplan.module#UpdatebusinessplanModule'},
          {path: 'businessplanview/:id', loadChildren: './businessconsulting/businessplan/businessplanview/businessplanview.module#BusinessplanviewModule'},
          {path: 'courier', loadChildren: './businessconsulting/courier/courierlist/courier.module#CourierModule'},
          {path: 'insertcourier', loadChildren: './businessconsulting/courier/insertcourier/insertcourier.module#InsertcourierModule'},
          {path: 'updatecourier/:id', loadChildren: './businessconsulting/courier/updatecourier/updatecourier.module#UpdatecourierModule'},
          {path: 'courierview/:id', loadChildren: './businessconsulting/courier/courierview/courierview.module#CourierviewModule'},
          {path: 'businessconsultingdashboard', loadChildren: './businessconsulting/dashboard/dashboard.module#DashboardModule'},
            {path: 'businessconsultingsummary', loadChildren: './businessconsulting/summary/summary.module#SummaryModule'},
          {path: 'assettypes', loadChildren: './masters/finance/assettype/assettypelist/assettypelist.module#AssettypelistModule'},
          {path: 'insertassettype', loadChildren: './masters/finance/assettype/insertassettype/insertassettype.module#InsertassettypeModule'},
          {path: 'updateassettype/:id', loadChildren: './masters/finance/assettype/updateassettype/updateassettype.module#UpdateassettypeModule'},
          {path: 'insertfinancetemplate', loadChildren: './masters/finance/insertfinancetemplates/insertfinancetemplates.module#InsertfinancetemplatesModule'},
          {path: 'financetemplate', loadChildren: './masters/finance/financetamplates/financetamplates.module#FinancetamplatesModule'},
          {path: 'updatefinancetemplates/:id', loadChildren: './masters/finance/updatefinancetemplates/updatefinancetemplates.module#UpdatefinancetemplatesModule'},
          {path: 'financetemplateview/:id', loadChildren: './masters/finance/financetemplatesview/financetemplatesview.module#FinancetemplatesviewModule'},
          {path: 'transactionsubtypes', loadChildren: './masters/finance/transactionsubtype/transactionsubtypelist/transactionsubtypelist.module#TransactionsubtypelistModule'},
          {path: 'inserttransactionsubtype', loadChildren: './masters/finance/transactionsubtype/inserttransactionsubtype/inserttransactionsubtype.module#InserttransactionsubtypeModule'},
          {path: 'transactionsubtypeview/:id', loadChildren: './masters/finance/transactionsubtype/transactionsubtypeview/transactionsubtypeview.module#TransactionsubtypeviewModule'},
          {path: 'updatetransactionsubtype/:id', loadChildren: './masters/finance/transactionsubtype/updatetransactionsubtype/updatetransactionsubtype.module#UpdatetransactionsubtypeModule'},
          {path: 'vehicletypes', loadChildren: './masters/finance/vehicletype/vehicletypelist/vehicletypelist.module#VehicletypelistModule'},
          {path: 'insertvehicletype', loadChildren: './masters/finance/vehicletype/insertvehicletype/insertvehicletype.module#InsertvehicletypeModule'},
          {path: 'vehicletypeview/:id', loadChildren: './masters/finance/vehicletype/vehicletypeview/vehicletypeview.module#VehicletypeviewModule'},
          {path: 'updatevehicletype/:id', loadChildren: './masters/finance/vehicletype/updatevehicletype/updatevehicletype.module#UpdatevehicletypeModule'},
          {path: 'partnerquestionnaires', loadChildren: './masters/franchise/partneerquestionnaire/partnerquestionnairelist/partnerquestionnairelist.module#PartnerquestionnairelistModule'},
          {path: 'insertpartnerquestionnaire', loadChildren: './masters/franchise/partneerquestionnaire/insertpartnerquestionnaire/insertpartnerquestionnaire.module#InsertpartnerquestionnaireModule'},
          {path: 'updatepartnerquestionnaire/:id', loadChildren: './masters/franchise/partneerquestionnaire/updatepartnerquestionnaire/updatepartnerquestionnaire.module#UpdatepartnerquestionnaireModule'},
          {path: 'payrolltemplates', loadChildren: './masters/payroll/payrolltemplates/payrolltemplates.module#PayrolltemplatesModule'},
          {path: 'insertpayrolltemplate', loadChildren: './masters/payroll/insertpayrolltemplate/insertpayrolltemplate.module#InsertpayrolltemplateModule'},
          {path: 'updatepayrolltemplates/:id', loadChildren: './masters/payroll/updatepayrolltemplate/updatepayrolltemplate.module#UpdatepayrolltemplateModule'},
          {path: 'payrolltemplateview/:id', loadChildren: './masters/payroll/payrolltemplateview/payrolltemplateview.module#PayrolltemplateviewModule'},
          {path: 'expensetypes', loadChildren: './masters/expensetypes/expensetypeslist/expensetypeslist.module#ExpensetypeslistModule'},
          {path: 'insertexpensetypes', loadChildren: './masters/expensetypes/insertexpensetypes/insertexpensetypes.module#InsertexpensetypesModule'},
          {path: 'updateexpensetypes/:id', loadChildren: './masters/expensetypes/updateexpensetypes/updateexpensetypes.module#UpdateexpensetypesModule'},

          {path: 'workitems', loadChildren: './masters/projectmanagement/workitems/workitemslist/workitemslist.module#WorkitemslistModule'},
          {path: 'insertworkitem', loadChildren: './masters/projectmanagement/workitems/insertworkitems/insertworkitems.module#InsertworkitemsModule'},
          {path: 'updateworkitem/:id', loadChildren: './masters/projectmanagement/workitems/updateworkitems/updateworkitems.module#UpdateworkitemsModule'},
          {path: 'compliances', loadChildren: './masters/projectmanagement/compliance/compliancelist/compliancelist.module#CompliancelistModule'},
          {path: 'insertcompliance', loadChildren: './masters/projectmanagement/compliance/insertcompliance/insertcompliance.module#InsertcomplianceModule'},
          {path: 'updatecompliance/:id', loadChildren: './masters/projectmanagement/compliance/updatecompliance/updatecompliance.module#UpdatecomplianceModule'},
          {path: 'projecttemplates', loadChildren: './masters/projectmanagement/projecttemplates/projecttemplates.module#ProjecttemplatesModule'},
          {path: 'insertprojecttemplate', loadChildren: './masters/projectmanagement/insertprojecttemplate/insertprojecttemplate.module#InsertprojecttemplateModule'},
          {path: 'updateprojecttemplates/:id', loadChildren: './masters/projectmanagement/updateprojecttemplate/updateprojecttemplate.module#UpdateprojecttemplateModule'},
          {path: 'projecttemplateview/:id', loadChildren: './masters/projectmanagement/projecttemplateview/projecttemplateview.module#ProjecttemplateviewModule'},
          {path: 'costcards', loadChildren: './masters/projectmanagement/costcard/costcardlist/costcardlist.module#CostcardModule'},
          {path: 'insertcostcard', loadChildren: './masters/projectmanagement/costcard/insertcostcard/insertcostcard.module#InsertcostcardModule'},
          {path: 'updatecostcard/:id', loadChildren: './masters/projectmanagement/costcard/updatecostcard/updatecostcard.module#UpdatecostcardModule'},
          {path: 'viewcostcard/:id', loadChildren: './masters/projectmanagement/costcard/costcardview/costcardview.module#costcardviewModule'},
          //
          {path: 'insertadvertising', loadChildren: './sales/advertisingcosting/insertadvertising/insertadvertising.module#InsertadvertisingModule'},
          {path: 'updateadvertising/:id', loadChildren: './sales/advertisingcosting/updateadvertising/updateadvertising.module#UpdateadvertisingModule'},
          {path: 'advertisingcostingview/:id',loadChildren: './sales/advertisingcosting/advertisingview/advertisingview.module#AdvertisingviewModule'},

            {path: 'billinginvoice', loadChildren: './about/billinginvoice/billinginvoice.module#BillinginvoiceModule'},
            {path: 'about', loadChildren: './about/about.module#AboutModule'},
            {path: 'managementdashboard', loadChildren: './management/managementdashboard/managementdashboard.module#ManagementdashboardModule'},
            {path: 'companygoals', loadChildren: './management/companygoals/companygoalslist/companygoalslist.module#CompanygoalslistModule'},
            {path: 'companygoalsview/:id', loadChildren: './management/companygoals/companygoalsview/companygoalsview.module#CompanygoalsviewModule'},
            {path: 'insertcompanygoals', loadChildren: './management/companygoals/insertcompanygoals/insertcompanygoals.module#InsertcompanygoalsModule'},
            {path: 'updatecompanygoals/:id', loadChildren: './management/companygoals/updatecompanygoals/updatecompanygoals.module#UpdatecompanygoalsModule'},
            {path: 'departmentgoals', loadChildren: './management/departmentgoallist/departmentgoallist.module#DepartmentgoallistModule'},
            {path: 'hrgoals', loadChildren: './management/hrgoal/hrgoal.module#HrgoalModule'},
            {path: 'business', loadChildren: './platformconfiguration/business/business.module#BusinessModule'},
            {path: 'parameters', loadChildren: './platformconfiguration/parameters/parameters.module#ParametersModule'},
            {path: 'documents', loadChildren: './platformconfiguration/documents/documents.module#DocumentsModule'},
            {path: 'roles' , loadChildren: './platformconfiguration/rolemanagement/rolemanagement.module#RolemanagementModule'},
            {path: 'importsamples', loadChildren: './platformconfiguration/importsample/importsample.module#ImportsampleModule'},
            {path: 'systemlogs', loadChildren: './platformconfiguration/systemlogs/systemlogs.module#SystemlogsModule'},
            {path: 'calendartimesheet', loadChildren: './payroll/calendartimesheet/calendartimesheet/calendartimesheet.module#CalendartimesheetModule'},
            {path: 'calendartimesheet/:id', loadChildren: './payroll/calendartimesheet/calendartimesheet/calendartimesheet.module#CalendartimesheetModule'},
            {path: 'viewcalendartimesheet/:id', loadChildren: './payroll/calendartimesheet/viewcalendartimesheet/viewcalendartimesheet.module#ViewcalendartimesheetModule'},
            {path: 'importtimesheet', loadChildren: './payroll/timesheet/importtimesheetlog/importtimesheet.module#ImporttimesheetModule',},
            {path: 'payslips', loadChildren: './payroll/payslip/paysliplist/payslips.module#PayslipsModule'},
            {path: 'payslipview/:id', loadChildren: './payroll/payslip/payslipview/payslipview.module#PayslipviewModule'},
            {path: 'leaveapplication', loadChildren: './payroll/leaveapplication/leaveapplicationlist/leaveapplication.module#LeaveapplicationModule'},
            {path: 'insertleaveapplication', loadChildren: './payroll/leaveapplication/insertleaveapplication/insertleaveapplication.module#InsertleaveapplicationModule'},
            {path: 'updateleaveapplication/:id', loadChildren: './payroll/leaveapplication/updateleaveapplication/updateleaveapplication.module#UpdateleaveapplicationModule'},
            {path: 'leaveapplicationview/:id', loadChildren: './payroll/leaveapplication/leaveapplicationview/leaveapplicationview.module#LeaveapplicationviewModule'},
            {path: 'incidents', loadChildren: './payroll/incident/incidentlist/incidents.module#IncidentsModule'},
            {path: 'insertincident', loadChildren: './payroll/incident/insertincident/insertincident.module#InsertincidentModule'},
            {path: 'updateincident/:id', loadChildren: './payroll/incident/updateincident/updateincident.module#UpdateincidentModule'},
            {path: 'incidentview/:id', loadChildren: './payroll/incident/incidentview/incidentview.module#IncidentviewModule'},
            {path: 'travelexpenses', loadChildren: './payroll/travelexpenses/travelexpenseslist/travelexpenses.module#TravelexpensesModule'},
            {path: 'inserttravelexpenses', loadChildren: './payroll/travelexpenses/inserttravelexpenses/inserttravelexpenses.module#InserttravelexpensesModule'},
            {path: 'updatetravelexpenses/:id', loadChildren: './payroll/travelexpenses/updatetravelexpenses/updatetravelexpenses.module#UpdatetravelexpensesModule'},
            {path: 'travelexpensesview/:id', loadChildren: './payroll/travelexpenses/travelexpensesview/travelexpensesview.module#TravelexpensesviewModule'},
            {path: 'loans', loadChildren: './payroll/loan/loans/loans.module#LoansModule'},
            {path: 'insertloan', loadChildren: './payroll/loan/insertloan/insertloan.module#InsertloanModule'},
            {path: 'updateloan/:id', loadChildren: './payroll/loan/updateloan/updateloan.module#UpdateloanModule'},
            {path: 'loanview/:id', loadChildren: './payroll/loan/loanview/loanview.module#LoanviewModule'},
            {path: 'insertloanrepayment', loadChildren: './payroll/loanrepayment/insertloanrepayment/insertloanrepayment.module#InsertloanrepaymentModule'},
            {path: 'bonus', loadChildren: './payroll/bonus/bonuslist/bonus.module#BonusModule'},
            {path: 'insertbonus', loadChildren: './payroll/bonus/insertbonus/insertbonus.module#InsertbonusModule'},
            {path: 'updatebonus/:id', loadChildren: './payroll/bonus/updatebonus/updatebonus.module#UpdatebonusModule'},
            {path: 'bonusview/:id', loadChildren: './payroll/bonus/bonusview/bonusview.module#BonusviewModule'},
            {path: 'incentives', loadChildren: './payroll/incentive/incentivelist/incentivelist.module#IncentivelistModule'},
            {path: 'insertincentive', loadChildren: './payroll/incentive/insertincentive/insertincentive.module#InsertincentiveModule'},
            {path: 'updateincentive/:id', loadChildren: './payroll/incentive/updateincentive/updateincentive.module#UpdateincentiveModule'},
            {path: 'incentiveview/:id', loadChildren: './payroll/incentive/incentiveview/incentiveview.module#IncentiveviewModule'},
            {path: 'arrears', loadChildren: './payroll/arrears/arrearlist/arrearlist.module#ArrearlistModule'},
            {path: 'insertarrear', loadChildren: './payroll/arrears/insertarrear/insertarrear.module#InsertarrearModule'},
            {path: 'updatearrear/:id', loadChildren: './payroll/arrears/updatearrear/updatearrear.module#UpdatearrearModule'},
            {path: 'arrearview/:id', loadChildren: './payroll/arrears/arrearview/arrearview.module#ArrearviewModule'},
            {path: 'complainttypes', loadChildren: './masters/crm/complaint/complainttypelist/complainttypes.module#ComplainttypeModule'},
            {path: 'insertcomplainttype', loadChildren: './masters/crm/complaint/insertcomplainttype/insertcomplainttype.module#InsertcomplainttypeModule'},
            {path: 'updatecomplainttype/:id', loadChildren: './masters/crm/complaint/updatecomplainttype/updatecomplainttype.module#UpdatecomplainttypeModule'},
            {path: 'servicetypes', loadChildren: './masters/crm/servicetype/servicetypelist/servicetypes.module#ServicetypesModule'},
            {path: 'insertservicetype', loadChildren: './masters/crm/servicetype/insertservicetype/insertservicetype.module#InsertservicetypeModule'},
            {path: 'updateservicetype/:id', loadChildren: './masters/crm/servicetype/updateservicetype/updateservicetype.module#UpdateservicetypeModule'},
            {path: 'crmtemplates', loadChildren: './masters/crm/crmtemplates/crmtemplates.module#CrmtemplatesModule'},
            {path: 'updatecrmtemplates/:id', loadChildren: './masters/crm/updatecrmtemplates/updatecrmtemplates.module#UpdatecrmtemplatesModule',},
            {path: 'insertcrmtemplate', loadChildren: './masters/crm/insertcrmtemplate/insertcrmtemplate.module#InsertcrmtemplateModule',},
            {path: 'crmtemplateview/:id', loadChildren: './masters/crm/crmtemplateview/crmtemplateview.module#CrmtemplateviewModule',},
            {path: 'customercategories', loadChildren: './masters/sales/customercategory/customercategorylist/customercategories.module#CustomercategoriesModule'},
            {path: 'insertcustomercategory', loadChildren: './masters/sales/customercategory/insertcustomercategory/insertcustomercategory.module#InsertcustomercategoryModule'},
            {path: 'updatecustomercategory/:id', loadChildren: './masters/sales/customercategory/updatecustomercategory/updatecustomercategory.module#UpdatecustomercategoryModule'},

            {path: 'salestemplates', loadChildren: './masters/sales/salestemplates/salestemplates.module#SalestemplatesModule'},
            {path: 'insertsalestemplate', loadChildren: './masters/sales/insertsalestemplate/insertsalestemplate.module#InsertsalestemplateModule'},
            {path: 'updatesalestemplates/:id', loadChildren: './masters/sales/updatesalestemplates/updatesalestemplates.module#UpdatesalestemplatesModule'},
            {path: 'salestemplateview/:id', loadChildren: './masters/sales/salestemplateview/salestemplateview.module#SalestemplateviewModule'},

          {path: 'insertenquiry', loadChildren: './businessconsulting/enquiry/insertenquiry/insertenquiry.module#InsertenquiryModule'},
          {path: 'updateenquiry/:id', loadChildren: './businessconsulting/enquiry/updateenquiry/updateenquiry.module#UpdateenquiryModule'},
          {path: 'enquiryview/:id', loadChildren: './businessconsulting/enquiry/enquiryview/enquiryview.module#EnquiryviewModule'},
          {path: 'enquiries', loadChildren: './businessconsulting/enquiry/enquirylist/enquirylist.module#EnquirylistModule'},
          {path: 'project', loadChildren: './businessconsulting/project/projectlist/projectlist.module#ProjectlistModule'},
          {path: 'insertbusinessproject', loadChildren: './businessconsulting/project/insertproject/insertproject.module#InsertprojectModule'},
          {path: 'updatebusinessproject/:id', loadChildren: './businessconsulting/project/updateproject/updateproject.module#UpdateprojectModule'},
          {path: 'businessprojectview/:id', loadChildren: './businessconsulting/project/projectview/projectview.module#ProjectviewModule'},
          {path: 'insertbusinessconsultingpurchaseorder', loadChildren: './businessconsulting/purchaseorder/insertpurchaseorder/insertpurchaseorder.module#InsertpurchaseorderModule'},
          {path: 'updatebusinessconsultingpurchaseorder/:id', loadChildren: './businessconsulting/purchaseorder/updatepurchaseorder/updatepurchaseorder.module#UpdatepurchaseorderModule'},
          {path: 'businessconsultingpurchaseorderview/:id', loadChildren: './businessconsulting/purchaseorder/purchaseorderview/purchaseorderview.module#PurchaseorderviewModule'},
          {path: 'businessconsultingpurchaseorder', loadChildren: './businessconsulting/purchaseorder/purchaseorderlist/purchaseorderlist.module#PurchaseorderlistModule'},
          {path: 'registrations', loadChildren: './businessconsulting/registrations/registrationlist/registrationlist.module#RegistrationlistModule'},
          {path: 'insertregistration', loadChildren: './businessconsulting/registrations/insertregistration/insertregistration.module#InsertregistrationModule'},
          {path: 'updateregistration/:id', loadChildren: './businessconsulting/registrations/updateregistration/updateregistration.module#UpdateregistrationModule'},
          {path: 'viewregistration/:id', loadChildren: './businessconsulting/registrations/viewregistration/viewregistration.module#ViewregistrationModule'},
          {path: 'tender', loadChildren: './businessconsulting/tenders/tenderlist/tenderlist.module#TenderlistModule'},
          {path: 'updatetender/:id', loadChildren: './businessconsulting/tenders/updatetender/updatetender.module#UpdatetenderModule'},
          {path: 'inserttender', loadChildren: './businessconsulting/tenders/inserttender/inserttender.module#InserttenderModule'},
          {path: 'tenderview/:id', loadChildren: './businessconsulting/tenders/tenderview/tenderview.module#TenderviewModule'},

            {path: 'leadsources', loadChildren: './masters/leadsource/leadsourcelist/leadsources.module#LeadsourcesModule'},
            {path: 'insertleadsource', loadChildren: './masters/leadsource/insertleadsource/insertleadsource.module#InsertleadsourceModule'},
            {path: 'updateleadsource/:id', loadChildren: './masters/leadsource/updateleadsource/updateleadsource.module#UpdateleadsourceModule'},

            {path: 'designations', loadChildren: './masters/designation/designationlist/designations.module#DesignationsModule'},
            {path: 'insertdesignation', loadChildren: './masters/designation/insertdesignation/insertdesignation.module#InsertdesignationModule'},
            {path: 'updatedesignation/:id', loadChildren: './masters/designation/updatedesignation/updatedesignation.module#UpdatedesignationModule'},

            {path: 'orientationquestionnaires', loadChildren: './masters/hr/orientationquestionnaire/questionnarielist/questionnarielist.module#QuestionnarielistModule'},
            {path: 'orientationquestionnaires/:id', loadChildren: './masters/hr/orientationquestionnaire/questionnarielist/questionnarielist.module#QuestionnarielistModule'},
            {path: 'insertorientationquestionnarie', loadChildren: './masters/hr/orientationquestionnaire/insertquestionnarie/insertquestionnarie.module#InsertquestionnarieModule'},
            {path: 'insertorientationquestionnarie/:id', loadChildren: './masters/hr/orientationquestionnaire/insertquestionnarie/insertquestionnarie.module#InsertquestionnarieModule'},
            {path: 'updateorientationquestionnarie/:id', loadChildren: './masters/hr/orientationquestionnaire/updatequestionnarie/updatequestionnarie.module#UpdatequestionnarieModule'},
            {path: 'orientationquestionnariesview/:id', loadChildren: './masters/hr/orientationquestionnaire/questionnaireview/questionnaireview.module#QuestionnaireviewModule'},

            {path: 'orientations', loadChildren: './masters/orientation/orientationlist/orientationlist.module#OrientationlistModule'},
            {path: 'orientationview/:id', loadChildren: "./masters/orientation/orientationview/orientationview.module#VieworientationModule"},
            {path: 'questionnaries', loadChildren: "./masters/hr/orientationquestionnaire/questionnarielist/questionnarielist.module#QuestionnarielistModule"},
            {path: 'insertorientation', loadChildren: './masters/orientation/insertorientation/insertorientation.module#InsertorientationModule'},
            {path: 'updateorientation/:id', loadChildren: './masters/orientation/updateorientation/updateorientation.module#UpdateorientationModule'},

            {path: 'degree', loadChildren: './masters/hr/degree/degreelist/degreelist.module#DegreelistModule'},
            {path: 'insertdegree', loadChildren: './masters/hr/degree/insertdegree/insertdegree.module#InsertdegreeModule'},
            {path: 'updatedegree/:id', loadChildren: './masters/hr/degree/updatedegree/updatedegree.module#UpdatedegreeModule'},

            {path: 'skills', loadChildren: './masters/hr/skills/skilllist/skilllist.module#SkilllistModule'},
            {path: 'insertskill', loadChildren: './masters/hr/skills/insertskill/insertskill.module#InsertskillModule'},
            {path: 'updateskill/:id', loadChildren: './masters/hr/skills/updateskill/updateskill.module#UpdateskillModule'},

            {path: 'jobtitle', loadChildren: './masters/hr/jobtitle/jobtitlelist/jobtitlelist.module#JobtitleModule'},
            {path: 'insertjobtitle', loadChildren: './masters/hr/jobtitle/insertjobtitle/insertjobtitle.module#InsertjobtitleModule'},
            {path: 'updatejobtitle/:id', loadChildren: './masters/hr/jobtitle/updatejobtitle/updatejobtitle.module#UpdatejobtitleModule'},

            {path: 'hrtemplates', loadChildren: './masters/hr/hrtemplates/hrtemplates.module#HrtemplatesModule'},
            {path: 'updatehrtemplates/:id', loadChildren: './masters/hr/updatehrtemplates/updatehrtemplates.module#UpdatehrtemplatesModule'},
            {path: 'inserthrtemplate', loadChildren: './masters/hr/inserthrtemplate/inserthrtemplate.module#InserthrtemplateModule'},
            {path: 'hrtemplateview/:id', loadChildren: './masters/hr/hrtemplateview/hrtemplateview.module#HrtemplateviewModule'},
            {path: 'hremailtemplates', loadChildren: './masters/hr/hrtemplateview/hrtemplateview.module#HrtemplateviewModule'},

          {path: 'suppliertypes', loadChildren: './masters/scm/suppliertype/suppliertypelist/suppliertypelist.module#SuppliertypelistModule'},
          {path: 'insertsuppliertype', loadChildren: './masters/scm/suppliertype/insertsuppliertype/insertsuppliertype.module#InsertsuppliertypeModule'},
          {path: 'updatesuppliertype/:id', loadChildren: './masters/scm/suppliertype/updatesuppliertype/updatesuppliertype.module#UpdatesuppliertypeModule'},

          {path: 'vendorfunctions', loadChildren: './masters/scm/vendorfunction/vendorfunctionlist/vendorfunctionlist.module#VendorfunctionlistModule'},
          {path: 'insertvendorfunction', loadChildren: './masters/scm/vendorfunction/insertvendorfunction/insertvendorfunction.module#InsertvendorfunctionModule'},
          {path: 'updatevendorfunction/:id', loadChildren: './masters/scm/vendorfunction/updatevendorfunction/updatevendorfunction.module#UpdatevendorfunctionModule'},
          {path: 'scmstatus', loadChildren: './masters/scm/scmstatus/scmstatuslist/scmstatuslist.module#ScmstatuslistModule'},
          {path: 'insertscmstatus', loadChildren: './masters/scm/scmstatus/insertscmstatus/insertscmstatus.module#InsertscmstatusModule'},
          {path: 'updatescmstatus/:id', loadChildren: './masters/scm/scmstatus/updatescmstatus/updatescmstatus.module#UpdatescmstatusModule'},
          {path: 'transportationtypes', loadChildren: './masters/scm/transportationtype/transportationtypelist/transportationtypelist.module#TransportationtypelistModule'},
          {path: 'inserttransportationtype', loadChildren: './masters/scm/transportationtype/inserttransportationtype/inserttransportationtype.module#InserttransportationtypeModule'},
          {path: 'updatetransportationtype/:id', loadChildren: './masters/scm/transportationtype/updatetransportationtype/updatetransportationtype.module#UpdatetransportationtypeModule'},
          {path: 'materials', loadChildren: './masters/scm/material/materiallist/materiallist.module#MateriallistModule'},
          {path: 'insertmaterial', loadChildren: './masters/scm/material/insertmaterial/insertmaterial.module#InsertmaterialModule'},
          {path: 'updatematerial/:id', loadChildren: './masters/scm/material/updatematerial/updatematerial.module#UpdatematerialModule'},
          {path: 'scmtemplates', loadChildren: './masters/scm/scmtemplates/scmtemplates.module#ScmtemplatesModule'},
          {path: 'updatescmtemplates/:id', loadChildren: './masters/scm/updatescmtemplates/updatescmtemplates.module#UpdatescmtemplatesModule'},
          {path: 'insertscmtemplate', loadChildren: './masters/scm/insertscmtemplate/insertscmtemplate.module#InsertscmtemplateModule'},
          {path: 'scmtemplateview/:id', loadChildren: './masters/scm/scmtemplateview/scmtemplateview.module#ScmtemplateviewModule'},
          {path: 'scmordersources', loadChildren: './masters/scm/scmordersources/scmordersourceslist/scmordersourceslist.module#ScmordersourceslistModule'},
          {path: 'insertscmordersources', loadChildren: './masters/scm/scmordersources/insertscmordersources/insertscmordersources.module#InsertscmordersourcesModule'},
          {path: 'updatescmordersources/:id', loadChildren: './masters/scm/scmordersources/updatescmordersources/updatescmordersources.module#UpdatescmordersourcesModule'},


          {path: 'rawmaterials', loadChildren: './masters/scm/rawmaterial/rawmateriallist/rawmateriallist.module#RawmateriallistModule'},
          {path: 'insertrawmaterial', loadChildren: './masters/scm/rawmaterial/insertrawmaterial/insertrawmaterial.module#InsertrawmaterialModule'},
          {path: 'updaterawmaterial/:id', loadChildren: './masters/scm/rawmaterial/updaterawmaterial/updaterawmaterial.module#UpdateproductModule'},
          {path: 'rawmaterialview/:id', loadChildren: './masters/scm/rawmaterial/rawmaterialview/rawmaterialview.module#RawmaterialviewModule'},
          {path: 'importrawmaterials', loadChildren: './masters/scm/rawmaterial/importrawmaterial/importrawmaterial.module#ImportrawmaterialModule'},
          {path: 'exportrawmaterial', loadChildren: './masters/scm/rawmaterial/exportrawmaterial/exportrawmaterial.module#ExportrawmaterialModule'},


            {path: 'projectmanagementdashboard', loadChildren: './projectmanagement/projectmanagementdashboard/projectmanagementdashboard.module#ProjectmanagementdashboardModule'},
            {path: 'pmsummary', loadChildren: './projectmanagement/summary/summary.module#SummaryModule'},
            {path: 'projects', loadChildren: './projectmanagement/project/projectlist/projects.module#ProjectsModule'},
            {path: 'insertproject', loadChildren: './projectmanagement/project/insertproject/insertproject.module#InsertprojectModule'},
            {path: 'updateproject/:id', loadChildren: './projectmanagement/project/updateproject/updateproject.module#UpdateprojectModule'},
            {path: 'projectview/:id', loadChildren: './projectmanagement/project/projectview/projectview.module#ProjectviewModule'},

            {path: 'tasks', loadChildren: './projectmanagement/task/tasklist/tasks.module#TasksModule'},
            {path: 'tasks/:id', loadChildren: './projectmanagement/task/tasklist/tasks.module#TasksModule'},
            {path: 'inserttask/:id', loadChildren: './projectmanagement/task/inserttask/inserttask.module#InserttaskModule'},
            {path: 'inserttask', loadChildren: './projectmanagement/task/inserttask/inserttask.module#InserttaskModule'},
            {path: 'updatetask/:id', loadChildren: './projectmanagement/task/updatetask/updatetask.module#UpdatetaskModule'},
            {path: 'taskview/:id', loadChildren: './projectmanagement/task/taskview/taskview.module#TaskviewModule'},

            {path: 'issues', loadChildren: './projectmanagement/issue/issueslist/issues.module#IssuesModule'},
            {path: 'insertissue/:id', loadChildren: './projectmanagement/issue/insertissue/insertissue.module#InsertissueModule'},
            {path: 'insertissue', loadChildren: './projectmanagement/issue/insertissue/insertissue.module#InsertissueModule'},
            {path: 'updateissue/:id', loadChildren: './projectmanagement/issue/updateissue/updateissue.module#UpdateissueModule'},
            {path: 'issueview/:id', loadChildren: './projectmanagement/issue/issueview/issueview.module#IssueviewModule'},

            {path:'allocation', loadChildren: './projectmanagement/allocations/allocationlist/allocationlist.module#AllocationlistModule'},
            {path:'insertallocation', loadChildren: './projectmanagement/allocations/insertallocation/insertallocation.module#InsertallocationModule'},
            {path:'allocationview/:id', loadChildren: './projectmanagement/allocations/allocationview/allocationview.module#AllocationviewModule'},
            {path:'updateallocation/:id', loadChildren: './projectmanagement/allocations/updateallocation/updateallocation.module#UpdateallocationModule'},
            {path: 'timesheets', loadChildren:'./payroll/timesheet/timesheetlist/timesheetlist.module#TimesheetlistModule'},
            {path: 'payrollhoddashboard', loadChildren: './payroll/payrollhoddashboard/payrollhoddashboard.module#PayrollhoddashboardModule'},
            {path: 'payrollsummary', loadChildren: './payroll/summary/summary.module#SummaryModule'},
            {path: 'supervisordashboard', loadChildren: './payroll/supervisordashboard/supervisordashboard.module#SupervisordashboardModule'},
            {path: 'categories', loadChildren: './masters/common/category/categorylist/categories.module#CategoriesModule'},
            {path: 'insertcategory', loadChildren: './masters/common/category/insertcategory/insertcategory.module#InsertcategoryModule'},
            {path: 'updatecategory/:id', loadChildren: './masters/common/category/updatecategory/updatecategory.module#UpdatecategoryModule'},
            {path: 'setordercategories', loadChildren: './masters/common/category/setordercategories/setordercategories.module#SetordercategoriesModule'},
           {path: 'logisticsandtrackingdashboard', loadChildren: './logistics/logisticsandtrackingdashboard/logisticsandtrackingdashboard.module#LogisticsandtrackingdashboardModule'},
           {path: 'ecommercedashboard', loadChildren: './ecommerce/ecommercedashboard/ecommercedashboard.module#EcommercedashboardModule'},
           {path: 'ecmreport', loadChildren: './ecommerce/ecmreport/ecmreport.module#EcmreportModule'},

           {path: 'insertpartner', loadChildren: './franchise/partner/insertpartner/insertpartner.module#InsertpartnerModule'},
          {path: 'updatepartner/:id', loadChildren: './franchise/partner/updatepartner/updatepartner.module#UpdatepartnerModule'},
          {path: 'partnerview/:id', loadChildren: './franchise/partner/partnerview/partnerview.module#PartnerviewModule'},
          {path: 'partners', loadChildren: './franchise/partner/partnerlist/partnerlist.module#PartnerlistModule'},
          {path: 'exportpartner', loadChildren: './franchise/partner/exportpartner/exportpartner.module#ExportpartnerModule'},
          {path: 'importpartner', loadChildren: './franchise/partner/importpartner/importpartner.module#ImportpartnerModule'},

          {path: 'commissions', loadChildren: './franchise/commission/commissionlist/commissionlist.module#CommissionlistModule'},
          {path: 'insertcommission', loadChildren: './franchise/commission/insertcommission/insertcommission.module#InsertcommissionModule'},
          {path: 'updatecommission/:id', loadChildren: './franchise/commission/updatecommission/updatecommission.module#UpdatecommissionModule'},
          {path: 'commissionview/:id', loadChildren: './franchise/commission/commissionview/commissionview.module#CommissionviewModule'},
          {path: 'scmdashboard', loadChildren: './scm/scmdashboard/scmdashboard.module#ScmdashboardModule'},
            {path: 'scmsummary', loadChildren: './scm/summary/summary.module#SummaryModule'},
          {path: 'productinventory', loadChildren: './scm/productinventory/productinventorylist/productinventorylist.module#ProductinventorylistModule'},
          {path: 'productinventorystockin', loadChildren: './scm/productinventory/productinventorystockin/productinventorystockin.module#ProductinventorystockinModule'},
          {path: 'productinventorystockout', loadChildren: './scm/productinventory/productinventorystockout/productinventorystockout.module#ProductinventorystockoutModule'},
          {path: 'productinventoryview/:id', loadChildren: './scm/productinventory/productinventoryview/productinventoryview.module#ProductinventoryviewModule'},
          {path: 'updateproductinventorystockin/:id', loadChildren: './scm/productinventory/updateproductinventorystockin/updateproductinventorystockin.module#UpdateproductinventorystockinModule'},
          {path: 'updateproductinventorystockout/:id', loadChildren: './scm/productinventory/updateproductinventorystockout/updateproductinventorystockout.module#UpdateproductinventorystockoutModule'},
          {path: 'rawmaterialinventory', loadChildren: './scm/rawmaterialinventory/rawmaterialinventorylist/rawmaterialinventorylist.module#RawmaterialinventorylistModule'},
          {path: 'rawmaterialinventorystockin', loadChildren: './scm/rawmaterialinventory/rawmaterialinventorystockin/rawmaterialinventorystockin.module#RawmaterialinventorystockinModule'},
          {path: 'rawmaterialinventorystockout', loadChildren: './scm/rawmaterialinventory/rawmaterialinventorystockout/rawmaterialinventorystockout.module#RawmaterialinventorystockoutModule'},
          {path: 'rawmaterialinventoryview/:id', loadChildren: './scm/rawmaterialinventory/rawmaterialinventoryview/rawmaterialinventoryview.module#RawmaterialinventoryviewModule'},
          {path: 'updaterawmaterialinventorystockin/:id', loadChildren: './scm/rawmaterialinventory/updaterawmaterialinventorystockin/updaterawmaterialinventorystockin.module#UpdaterawmaterialinventorystockinModule'},
          {path: 'updaterawmaterialinventorystockout/:id', loadChildren: './scm/rawmaterialinventory/updaterawmaterialinventorystockout/updaterawmaterialinventorystockout.module#UpdaterawmaterialinventorystockoutModule'},
          {path: 'procurements', loadChildren: './scm/procurement/procurementlist/procurementlist.module#ProcurementlistModule'},
          {path: 'insertprocurement', loadChildren: './scm/procurement/insertprocurement/insertprocurement.module#InsertprocurementModule'},
          {path: 'procurementview/:id', loadChildren: './scm/procurement/procurementview/procurementview.module#ProcurementviewModule'},
          {path: 'updateprocurement/:id', loadChildren: './scm/procurement/updateprocurement/updateprocurement.module#UpdateprocurementModule'},
          {path: 'orders', loadChildren: './scm/order/orderlist/orderlist.module#OrdersModule'},
          {path: 'insertscmorder', loadChildren: './scm/order/insertorder/insertorder.module#InsertorderModule'},
          {path: 'updatescmorder/:id', loadChildren: './scm/order/updateorder/updateorder.module#UpdateOrderModule'},
          {path: 'scmorderview/:id', loadChildren: './scm/order/orderview/orderview.module#OrderViewModule'},
          {path: 'orderjoblist', loadChildren: './scm/orderjobs/orderjoblist/orderjoblist.module#OrderjobsModule'},
          {path: 'jobplannings', loadChildren: './scm/jobplanning/jobplanninglist/jobplanninglist.module#JobplanninglistModule'},
          {path: 'insertjobplanning', loadChildren: './scm/jobplanning/insertjobplanning/insertjobplanning.module#InsertjobplanningModule'},
          {path: 'jobplanningview/:id', loadChildren: './scm/jobplanning/jobplanningview/jobplanningview.module#JobplanningviewModule'},
          {path: 'updatejobplanning/:id', loadChildren: './scm/jobplanning/updatejobplanning/updatejobplanning.module#UpdatejobplanningModule'},
          {path: 'productpurchaseorders', loadChildren: './scm/productpurchaseorder/purchaseorderlist/purchaseorderlist.module#PurchaseordersModule'},
          {path: 'insertpurchaseorder', loadChildren: './scm/productpurchaseorder/insertpurchaseorder/insertpurchaseorder.module#InsertpurchaseorderModule'},
          {path: 'insertpurchaseorder/:id', loadChildren: './scm/productpurchaseorder/insertpurchaseorder/insertpurchaseorder.module#InsertpurchaseorderModule'},
          {path: 'updatepurchaseorder/:id', loadChildren: './scm/productpurchaseorder/updatepurchaseorder/updatepurchaseorder.module#UpdatepurchaseorderModule'},
          {path: 'purchaseorderview/:id', loadChildren: './scm/productpurchaseorder/purchaseorderview/purchaseorderview.module#PurchaseorderviewModule'},
          {path: 'rawmaterialpurchaseorders', loadChildren: './scm/rawmaterialpurchaseorder/rawmaterialpurchaseorderlist/rawmaterialpurchaseorderlist.module#RawmaterialpurchaseordersModule'},
          {path: 'insertrawmaterialpurchaseorder', loadChildren: './scm/rawmaterialpurchaseorder/insertrawmaterialpurchaseorder/insertrawmaterialpurchaseorder.module#InsertrawmaterialpurchaseorderModule'},
          {path: 'updaterawmaterialpurchaseorder/:id', loadChildren: './scm/rawmaterialpurchaseorder/updaterawmaterialpurchaseorder/updaterawmaterialpurchaseorder.module#UpdaterawmaterialpurchaseorderModule'},
          {path: 'rawmaterialpurchaseorderview/:id', loadChildren: './scm/rawmaterialpurchaseorder/rawmaterialpurchaseorderview/rawmaterialpurchaseorderview.module#RawmaterialpurchaseorderviewModule'},
          {path: 'exportvendor', loadChildren: './scm/vendor/exportvendor/exportvendor.module#ExportvendorModule'},
          {path: 'importvendor', loadChildren: './scm/vendor/importvendor/importvendor.module#ImportvendorModule'},
          {path: 'vendors', loadChildren: './scm/vendor/vendorlist/vendorlist.module#VendorsModule'},
          {path: 'insertvendor', loadChildren: './scm/vendor/insertvendor/insertvendor.module#InsertvendorModule'},
          {path: 'updatevendor/:id', loadChildren: './scm/vendor/updatevendor/updatevendor.module#UpdatevendorModule'},
          {path: 'viewvendor/:id', loadChildren: './scm/vendor/viewvendor/viewvendor.module#ViewvendorModule'},
          {path: 'suppliers', loadChildren: './scm/supplier/supplierlist/supplierlist.module#SupplierlistModule'},
          {path: 'insertsupplier', loadChildren: './scm/supplier/insertsupplier/insertsupplier.module#InsertsupplierModule'},
          {path: 'updatesupplier/:id', loadChildren: './scm/supplier/updatesupplier/updatesupplier.module#UpdatesupplierModule'},
          {path: 'supplierview/:id', loadChildren: './scm/supplier/supplierview/supplierview.module#SupplierviewModule'},
          {path: 'exportsupplier', loadChildren: './scm/supplier/exportsupplier/exportsupplier.module#ExportsupplierModule'},
          {path: 'importsupplier', loadChildren: './scm/supplier/importsupplier/importsupplier.module#ImportsupplierModule'},
          {path: 'planning', loadChildren: './scm/planning/planninglist/planninglist.module#PlanninglistModule'},
          {path: 'insertplanning', loadChildren: './scm/planning/insertplanning/insertplanning.module#InsertplanningModule'},
          {path: 'planningview/:id', loadChildren: './scm/planning/planningview/planningview.module#planningviewModule'},
          {path: 'updateplanning/:id', loadChildren: './scm/planning/updateplanning/updateplanning.module#UpdateplanningModule'},
          {path: 'productionconfigurationview/:id', loadChildren: './scm/productionconfiguration/productionconfigurationview/productionconfigurationview.module#ProductionconfigurationviewModule'},
          {path: 'updateproductionconfiguration/:id', loadChildren: './scm/productionconfiguration/updateproductionconfiguration/updateproductionconfiguration.module#UpdateproductionconfigurationModule'},
          {path: 'productionconfiguration', loadChildren: './scm/productionconfiguration/productionconfigurationlist/productionconfigurationlist.module#ProductionconfigurationlistModule'},
          {path: 'insertproductionconfiguration', loadChildren: './scm/productionconfiguration/insertproductionconfiguration/insertproductionconfiguration.module#InsertproductionconfigurationModule'},

          {path: 'qualitycontrol', loadChildren: './scm/qualitycontrol/qualitycontrollist/qualitycontrollist.module#QualitycontrollistModule'},

          {path: 'accountgroups', loadChildren: './finance/accountgroups/accountgroupslist/accountgroupslist.module#AccountgroupslistModule'},
          {path: 'insertaccountgroup', loadChildren: './finance/accountgroups/insertaccountgroup/insertaccountgroup.module#InsertaccountgroupModule'},
          {path: 'accountgroupview/:id', loadChildren: './finance/accountgroups/accountgroupview/accountgroupview.module#AccountgroupviewModule'},
          {path: 'updateaccountgroup/:id', loadChildren: './finance/accountgroups/updateaccountgroup/updateaccountgroup.module#UpdateaccountgroupModule'},

          {path: 'financedashboard', loadChildren: './finance/financedashboard/financedashboard.module#FinancedashboardModule'},

          {path: 'paymentslip', loadChildren: './finance/paymentslip/paymentsliplist/paymentsliplist.module#PaymentsliplistModule'},
          {path: 'insertpaymentslip', loadChildren: './finance/paymentslip/insertpaymentslip/insertpaymentslip.module#InsertpaymentslipModule'},
          {path: 'paymentslipview/:id', loadChildren: './finance/paymentslip/paymentslipview/paymentslipview.module#PaymentslipviewModule'},
          {path: 'updatepaymentslip/:id', loadChildren: './finance/paymentslip/updatepaymentslip/updatepaymentslip.module#UpdatepaymentslipModule'},
          {path: 'accounts', loadChildren: './finance/accounts/accountslist/accountslist.module#AccountslistModule'},

          {path: 'advertisingcostings', loadChildren: './sales/advertisingcosting/advertisinglist/advertisinglist.module#AdvertisinglistModule'},
          //
          {path: 'overheadcosts', loadChildren: './finance/costing/overheadcost/overheadcostlist/overheadcostlist.module#OverheadcostlistModule'},
          {path: 'insertsalary', component: InsertsalaryComponent, canActivate: [AuthGuard]},
          {path: 'updatesalary/:id', component: UpdatesalaryComponent, canActivate: [AuthGuard]},
          {path: 'salaryview/:id', component: SalaryviewComponent, canActivate: [AuthGuard]},

          {path: 'expenses', loadChildren: './finance/expenses/expenselist/expenselist.module#ExpenselistModule'},
          {path: 'insertexpense', loadChildren: './finance/expenses/insertexpense/insertexpense.module#InsertexpenseModule'},
          {path: 'updateexpense/:id', loadChildren: './finance/expenses/updateexpense/updateexpense.module#UpdateexpenseModule'},
          {path: 'viewexpense/:id', loadChildren: './finance/expenses/viewexpense/viewexpense.module#ViewexpenseModule'},

          {path: 'ledger', loadChildren: './finance/ledger/ledgerlist/ledgerlist.module#LedgerlistModule'},

          {path: 'assetallocation', loadChildren: './finance/assetallocationlist/assetallocationlist.module#AssetallocationlistModule'},
          {path: 'assetallocationview/:id', loadChildren: './finance/assetallocationview/assetallocationview.module#AssetallocationviewModule'},
          {path: 'insertassetallocation', loadChildren: './finance/insertassetallocation/insertassetallocation.module#InsertassetallocationModule'},
          {path: 'updateassetallocation/:id', loadChildren: './finance/updateassetallocation/updateassetallocation.module#UpdateassetallocationModule'},

          {path: 'balancesheets', loadChildren: './finance/balancesheets/balancesheetlist/balancesheetlist.module#BalancesheetlistModule'},

          {path: 'profitandloss', loadChildren: './finance/profitandloss/profitandlosslist/profitandlosslist.module#ProfitandlosslistModule'},

          {path: 'transactionsubtypes', loadChildren: './masters/finance/transactionsubtype/transactionsubtypelist/transactionsubtypelist.module#TransactionsubtypelistModule'},
          {path: 'inserttransactionsubtype', loadChildren: './masters/finance/transactionsubtype/inserttransactionsubtype/inserttransactionsubtype.module#InserttransactionsubtypeModule'},
          {path: 'transactionsubtypeview/:id', loadChildren: './masters/finance/transactionsubtype/transactionsubtypeview/transactionsubtypeview.module#TransactionsubtypeviewModule'},
          {path: 'updatetransactionsubtype/:id', loadChildren: './masters/finance/transactionsubtype/updatetransactionsubtype/updatetransactionsubtype.module#UpdatetransactionsubtypeModule'},

          {path: 'sizes', loadChildren: './masters/common/size/sizelist/sizes.module#SizesModule'},
          {path: 'insertsize', loadChildren: './masters/common/size/insertsize/insertsize.module#InsertsizeModule'},
          {path: 'updatesize/:id', loadChildren: './masters/common/size/updatesize/updatesize.module#UpdatesizeModule'},
          {path: 'measurements', loadChildren: './masters/common/measurement/measurementlist/measurementlist.module#MeasurementlistModule'},
          {path: 'insertmeasurement', loadChildren: './masters/common/measurement/insertmeasurement/insertmeasurement.module#InsertmeasurementModule'},
          {path: 'updatemeasurement/:id', loadChildren: './masters/common/measurement/updatemeasurement/updatemeasurement.module#UpdatemeasurementModule'},


          {path: 'trainings', loadChildren: './masters/common/training/traininglist/trainings.module#TrainingsModule'},
          {path: 'inserttraining', loadChildren: './masters/common/training/inserttraining/inserttraining.module#InserttrainingModule'},
          {path: 'updatetraining/:id', loadChildren: './masters/common/training/updatetraining/updatetraining.module#UpdatetrainingModule',},
          {path: 'questionnaires', loadChildren: './masters/common/questionnaire/questionnairelist/questionnaire.module#QuestionnaireModule'},
          {path: 'insertquestionnaire', loadChildren: './masters/common/questionnaire/insertquestionnaire/insertquestionnaire.module#InsertquestionnaireModule'},
          {path: 'updatequestionnaire/:id', loadChildren: './masters/common/questionnaire/updatequestionnaire/updatequestionnaire.module#UpdatequestionnaireModule'},
          {path: 'setorderquestionnaire', loadChildren: './masters/common/questionnaire/setorderquestionnaire/setorderquestionnaire.module#SetorderquestionnaireModule'},
          {path: 'questionnaireview/:id', loadChildren: './masters/common/questionnaire/viewquestionnaire/viewquestionnaire.module#ViewquestionnaireModule'},


          {path: 'industries', loadChildren: './masters/common/industry/industrylist/industrylist.module#IndustrylistModule'},
          {path: 'insertindustry', loadChildren: './masters/common/industry/insertindustry/insertindustry.module#InsertindustryModule'},
          {path: 'updateindustry/:id', loadChildren: './masters/common/industry/updateindustry/updateindustry.module#UpdateindustryModule'},
          {path: 'subcategories', loadChildren: './masters/common/subcategory/subcategorylist/subcategories.module#SubcategoriesModule'},
          {path: 'insertsubcategory', loadChildren: './masters/common/subcategory/insertsubcategory/insertsubcategory.module#InsertsubcategoryModule'},
          {path: 'updatesubcategory/:id', loadChildren: './masters/common/subcategory/updatesubcategory/updatesubcategory.module#UpdatesubcategoryModule'},

          {path: 'creditnote', loadChildren: './finance/creditnote/creditnotelist/creditnotelist.module#CreditnotelistModule'},
          {path: 'insertcreditnote', loadChildren: './finance/creditnote/insertcreditnote/insertcreditnote.module#InsertcreditnoteModule'},
          {path: 'creditnoteview/:id', loadChildren: './finance/creditnote/creditnoteview/creditnoteview.module#CreditnoteviewModule'},
          {path: 'updatecreditnote/:id', loadChildren: './finance/creditnote/updatecreditnote/updatecreditnote.module#UpdatecreditnoteModule'},

          {path: 'insertdebitnote', loadChildren: './finance/debitnote/insertdebitnote/insertdebitnote.module#InsertdebitnoteModule'},
          {path: 'debitnoteview/:id', loadChildren: './finance/debitnote/debitnoteview/debitnoteview.module#DebitnoteviewModule'},
          {path: 'updatedebitnote/:id', loadChildren: './finance/debitnote/updatedebitnote/updatedebitnote.module#UpdatedebitnoteModule'},
          {path: 'debitnote', loadChildren: './finance/debitnote/debitnotelist/debitnotelist.module#DebitnotelistModule'},

          {path: 'gstreport', loadChildren: './finance/gstservice/gstreport/gstreport.module#GstreportModule'},

          {path: 'transactions', loadChildren: './finance/transactions/transactionslist/transactionslist.module#TransactionslistModule'},
          {path: 'inserttransactions', loadChildren: './finance/transactions/inserttransactions/inserttransactions.module#InserttransactionsModule'},
          {path: 'transactionsview/:id', loadChildren: './finance/transactions/transactionsview/transactionsview.module#TransactionsviewModule'},
          {path: 'updatetransactions/:id', loadChildren: './finance/transactions/updatetransactions/updatetransactions.module#UpdatetransactionsModule'},

          {path: 'insertaccounts', loadChildren: './finance/accounts/insertaccount/insertaccount.module#InsertaccountModule'},
          {path: 'updateaccounts/:id', loadChildren: './finance/accounts/updateaccount/updateaccount.module#UpdateaccountModule'},
          {path: 'viewaccount/:id', loadChildren: './finance/accounts/viewaccount/viewaccount.module#ViewaccountModule'},


          {path: 'insertoverheadcost', loadChildren: './finance/costing/overheadcost/insertoverheadcost/insertoverheadcost.module#InsertoverheadcostModule'},
          {path: 'overheadcosts', loadChildren: './finance/costing/overheadcost/overheadcostlist/overheadcostlist.module#OverheadcostlistModule'},
          {path: 'updateoverheadcost/:id', loadChildren: './finance/costing/overheadcost/updateoverheadcost/updateoverheadcost.module#UpdateoverheadcostModule'},
          {path: 'overheadcostview/:id', loadChildren: './finance/costing/overheadcost/overheadcostview/overheadcostview.module#OverheadcostviewModule'},


          {path: 'candidates', loadChildren: './humanresources/candidate/candidatelist/candidates.module#CandidatesModule'},
          {path: 'candidateview/:id', loadChildren: './humanresources/candidate/candidateview/candidateview.module#CandidateviewModule'},
          {path: 'insertcandidate', loadChildren: './humanresources/candidate/insertcandidate/insertcandidate.module#InsertcandidateModule'},
          {path: 'updatecandidate/:id', loadChildren: './humanresources/candidate/updatecandidate/updatecandidate.module#UpdatecandidateModule'},
          {path: 'importcandidate', loadChildren: './humanresources/candidate/importcandidatelog/importcandidatelog.module#ImportcandidatelogModule'},
          {path: 'exportcandidate', loadChildren: './humanresources/candidate/exportcandidate/exportcandidate.module#ExportcandidateModule'},
          {path: 'insertcontract/:id', loadChildren: './humanresources/employee/contract/insertcontract/insertcontract.module#InsertcontractModule'},
          {path: 'employees', loadChildren: './humanresources/employee/employeelist/employees.module#EmployeesModule' },
          {path: 'insertemployee', loadChildren: './humanresources/employee/insertemployee/insertemployee.module#InsertemployeeModule'},
          {path: 'updateemployee/:id', loadChildren: './humanresources/employee/updateemployee/updateemployee.module#UpdateemployeeModule'},
          {path: 'employeeview/:id', loadChildren: './humanresources/employee/employeeview/employeeview.module#EmployeeviewModule'},
          {path: 'exportemployee', loadChildren: './humanresources/employee/exportemployee/exportemployee.module#ExportemployeeModule'},
          {path: 'importemployee', loadChildren: './humanresources/employee/importemployeelog/importemployee.module#ImportemployeeModule'},
          {path: 'importemployeepayrolldetail', loadChildren: './humanresources/employee/importemployeepayrolldetail/importemployeepayrolldetail.module#ImportemployeepayrolldetailModule'},
          {path: 'employer', loadChildren: './humanresources/employer/employer.module#EmployerModule'},
          {path: 'hrhoddashboard', loadChildren: './humanresources/hrhoddashboard/hrhoddashboard.module#HrhoddashboardModule'},
            {path: 'hrsummary', loadChildren: './humanresources/summary/summary.module#SummaryModule'},
          {path: 'hrgeneralistdashboard', loadChildren: './humanresources/hrgeneralistdashboard/hrgeneralistdashboard.module#HrgeneralistdashboardModule'},
          {path: 'hrhoddashboard', loadChildren: './humanresources/hrhoddashboard/hrhoddashboard.module#HrhoddashboardModule'},
          {path: 'jobopenings', loadChildren:'./humanresources/jobopening/jobopeninglist/jobopening.module#JobOpeningModule'},
          {path: 'insertjobopening', loadChildren: './humanresources/jobopening/insertjobopening/insertjobopening.module#InsertjobopeningModule'},
          {path: 'updatejobopening/:id', loadChildren: './humanresources/jobopening/updatejobopening/updatejobopening.module#UpdatejobopeningModule'},
          {path: 'jobopeningview/:id', loadChildren: './humanresources/jobopening/jobopeningview/jobopeningview.module#JobopeningviewModule'},
          {path: 'mcqview/:id', loadChildren: './humanresources/jobopening/mcqview/mcqview.module#McqviewModule'},
          {path: 'kra', loadChildren: './humanresources/kra/kralist/kralist.module#KralistModule'},
          {path: 'orientation', loadChildren: './humanresources/orientation/orientation.module#OrientationModule'},
          {path: 'insertorientation/:id/:employee_id', loadChildren: './humanresources/orientationtest/insertorientationtest/insertorientationtest.module#InsertorientationtestModule'},
          {path: 'performancemanagements', loadChildren: './humanresources/performancemanagement/performancemanagementlist/performancemanagements.module#PerformancemanagementsModule'},
          {path: 'performancekpi/:id', loadChildren: './humanresources/performancemanagement/performancekpilist/performancekpi.module#PerformancekpiModule'},
          {path: 'insertperformancemanagement/:id', loadChildren: './humanresources/performancemanagement/insertperformancemanagement/insertperformancemanagement.module#InsertperformancemanagementModule'},
          {path: 'performancekpiview/:id/:employee_id', loadChildren: './humanresources/performancemanagement/performancekpiview/performancekpiview.module#PerformancekpiviewModule'},
          {path: 'employeetrainingview/:id/:employee_id', loadChildren: './humanresources/performancemanagement/employeetrainingview/employeetrainingview.module#EmployeetrainingviewModule'},
          {path: 'insertemployeetraining/:id', loadChildren: './humanresources/performancemanagement/insertemployeetraining/insertemployeetraining.module#InsertemployeetrainingModule'},
          {path: 'updateemployeetraining/:id/:employee_id', loadChildren: './humanresources/performancemanagement/updateemployeetraining/updateemployeetraining.module#UpdateemployeetrainingModule'},
          {path: 'updateperformancekpi/:id/:employee_id', loadChildren: './humanresources/performancemanagement/updateperformancekpi/updateperformancekpi.module#UpdateperformancekpiModule'},
          {path: 'performancekpi', loadChildren: './humanresources/perofrmancekpi/kpilist/kpilist.module#KpilistModule'},
          {path: 'insertperformancekpi', loadChildren: './humanresources/perofrmancekpi/insertkpi/insertkpi.module#InsertkpiModule'},
          {path: 'performancekpiview/:id', loadChildren: './humanresources/perofrmancekpi/kpiview/kpiview.module#KpiviewModule'},
          {path: 'updateperformancekpi/:id', loadChildren: './humanresources/perofrmancekpi/updatekpi/updatekpi.module#UpdatekpiModule'},
          {path: 'training', loadChildren: './humanresources/training/traininglist/traininglist.module#TraininglistModule'},
          {path: 'inserttraininghr', loadChildren: './humanresources/training/inserttraining/inserttraining.module#InserttrainingModule'},
          {path: 'update/:id', loadChildren: './humanresources/training/updatetraining/updatetraining.module#UpdatetrainingModule'},
          {path: 'view/:id', loadChildren: './humanresources/training/trainingview/trainingview.module#TrainingviewModule'},
          {path: 'products', loadChildren: './masters/common/product/productlist/products.module#ProductsModule'},
          {path: 'insertproduct', loadChildren: './masters/common/product/insertproduct/insertproduct.module#InsertproductModule'},
          {path: 'updateproduct/:id', loadChildren: './masters/common/product/updateproduct/updateproduct.module#UpdateproductModule'},
          {path: 'productview/:id', loadChildren: './masters/common/product/productview/productview.module#ProductviewModule',},
          {path: 'importproduct', loadChildren: './masters/common/product/importproductlog/importproduct.module#ImportproductModule',},
          {path: 'exportproduct', loadChildren: './masters/common/product/exportproduct/exportproduct.module#ExportproductModule'},
          {path: 'barcodeproduct', loadChildren: './masters/common/product/barcodeproduct/barcodeproduct.module#BarcodeproductModule'},
          {path: 'ratecard', loadChildren: './masters/common/ratecard/ratecard/ratecard.module#RatecardModule'},
          {path: 'insertratecard', loadChildren: './masters/common/ratecard/insertratecard/insertratecard.module#InsertratecardModule'},
          {path: 'ratecardview/:id', loadChildren: './masters/common/ratecard/ratecardview/ratecardview.module#RatecardviewModule'},
          {path: 'updateratecard/:id', loadChildren: './masters/common/ratecard/updateratecard/updateratecard.module#UpdateratecardModule'},
          {path: 'importratecard', loadChildren: './masters/common/ratecard/importratecardlog/importratecard.module#ImportratecardModule'},
          {path: 'units', loadChildren: './masters/common/unit/unitlist/units.module#UnitsModule'},
          {path: 'insertunit', loadChildren: './masters/common/unit/insertunit/insertunit.module#InsertunitModule'},
          {path: 'updateunit/:id', loadChildren: './masters/common/unit/updateunit/updateunit.module#UpdateunitModule'},
          {path: 'kpis', loadChildren: './masters/common/kpi/kpilist/kpis.module#KpisModule'},
          {path: 'insertkpi', loadChildren: './masters/common/kpi/insertkpi/insertkpi.module#InsertkpiModule'},
          {path: 'updatekpi/:id', loadChildren: './masters/common/kpi/updatekpi/updatekpi.module#UpdatekpiModule'},
            {path: 'bookingtypes', loadChildren: './masters/businessconsulting/bookingtypes/bookingtypelist/bookingtypelist.module#BookingtypelistModuleModule'},
            {path: 'insertbookingtypes', loadChildren: './masters/businessconsulting/bookingtypes/insertbookingtype/insertbookingtype.module#InsertbookingtypeModule'},
            {path: 'updatebookingtypes/:id', loadChildren: './masters/businessconsulting/bookingtypes/updatebookingtype/updatebookingtype.module#UpdatebookingtypeModule'},
            {path: 'departments', loadChildren: './masters/common/department/departmentlist/departmentlist.module#DepartmentlistModule'},
            {path: 'insertdepartment', loadChildren: './masters/common/department/insertdepartment/insertdepartment.module#InsertdepartmentModule'},
            {path: 'updatedepartment/:id', loadChildren: './masters/common/department/updatedepartment/updatedepartment.module#UpdatedepartmentModule'},


            {path: 'countries', loadChildren: './masters/common/country/countrylist/countries.module#CountriesModule'},
            {path: 'insertcountry', loadChildren: './masters/common/country/insertcountry/insertcountry.module#InsertcountryModule'},
            {path: 'updatecountry/:id', loadChildren: './masters/common/country/updatecountry/updatecountry.module#UpdatecountryModule'},
            {path: 'states', loadChildren: './masters/common/state/statelist/states.module#StatesModule'},
            {path: 'insertstate', loadChildren: './masters/common/state/insertstate/insertstate.module#InsertstateModule'},
            {path: 'updatestate/:id', loadChildren: './masters/common/state/updatestate/updatestate.module#UpdatestateModule'},
            {path: 'cities', loadChildren: './masters/common/city/citylist/cities.module#CitiesModule'},
            {path: 'insertcity', loadChildren: './masters/common/city/insertcity/insertcity.module#InsertcityModule'},
            {path: 'updatecity/:id', loadChildren: './masters/common/city/updatecity/updatecity.module#UpdatecityModule'},
          {path: 'visitplannings', loadChildren: './businessconsulting/visitplanning/visitplanninglist/visitplanninglist.module#VisitplanninglistModule'},
          {path: 'insertvisitplanning', loadChildren: './businessconsulting/visitplanning/insertvisitplanning/insertvisitplanning.module#InsertvisitplanningModule'},
          {path: 'updatevisitplanning/:id', loadChildren: './businessconsulting/visitplanning/updatevisitplanning/updatevisitplanning.module#UpdatevisitplanningModule'},
          {path: 'visitplanningview/:id', loadChildren: './businessconsulting/visitplanning/visitplanningview/visitplanningview.module#VisitplanningviewModule'},
          {path: 'noaccesspage', loadChildren: './errors/noaccess/noaccess.module#NoaccessModule'},
          {path: 'posdashboard', loadChildren: './pos/posdashboard/posdashboard.module#PosdashboardModule'},
          {path: 'contacttypes', loadChildren: './masters/common/contacttypes/contacttypelist/contacttypelist.module#ContacttypelistModule'},
          {path: 'insertcontacttype', loadChildren: './masters/common/contacttypes/insertcontacttype/insertcontacttype.module#InsertcontacttypeModule'},
          {path: 'updatecontacttype/:id', loadChildren: './masters/common/contacttypes/updatecontacttype/updatecontacttype.module#UpdatecontacttypeModule'},


          {path: 'productcatalog', loadChildren: './masters/common/product/productcatalog/productcatalog.module#ProductcatalogModule'},
          {path: 'paymentmethods', loadChildren: './billing/paymentmethod/paymentmethod.module#PaymentmethodModule'},
          {path: 'subscription', loadChildren: './billing/subscription/subscription.module#SubscriptionModule'}
        ]
        // , canActivate: [AuthGuard]
    },
  {    path: '',
    component: SidemenulayoutComponent,
    children: [
      {path: 'salescostanalysisreport', loadChildren: './sales/report/salescostanalysisreport/salescostanalysisreport.module#SalescostanalysisreportModule'},
      {path: 'amcreport', loadChildren: './crm/report/amcreport/amcreport.module#AmcreportModule'},
      {path: 'amcservicereport', loadChildren: './crm/report/amcservicereport/amcservicereport.module#AmcservicereportModule'},
      {path: 'jobworkreport', loadChildren: './crm/report/jobworkreport/jobworkreport.module#JobworkreportModule'},
      {path: 'complaintreport', loadChildren: './crm/report/complaintreport/complaintreport.module#ComplaintreportModule'},

      {path: 'hcvareport', loadChildren: './sales/report/hcvareport/hcvareport.module#HcvareportModule'},
      {path: 'salescostanalysisreport', loadChildren: './sales/report/salescostanalysisreport/salescostanalysisreport.module#SalescostanalysisreportModule'},
      {path: 'leadcostanalysisreport', loadChildren: './sales/report/leadcostanalysisreport/leadcostanalysisreport.module#LeadcostanalysisreportModule'},
      {path: 'targetreport', loadChildren: './sales/report/targetreport/targetreport.module#TargetreportModule'},
      {path: 'newleadoutflowreport', loadChildren: './sales/report/newleadoutflowreport/newleadoutflowreport.module#NewleadoutflowreportModule'},
      {path: 'adcostpersalereport', loadChildren:'./sales/report/adcostpersalereport/adcostpersalereport.module#AdcostpersalereportModule'},
      {path: 'warrantyservicereport', loadChildren: './sales/report/warrantyservicereport/warrantyservicereport.module#WarrantyservicereportModule'},
      {path: 'targetandactualreport',loadChildren: './sales/report/targetandactualreport/targetandactualreport.module#TargetandactualreportModule'},
      {path: 'targetandactaulallocatedreport', loadChildren: './sales/report/targetandactaulallocatedreport/targetandactualallocatedreport.module#TargetandactualallocatedreportModule'},
      {path: 'averageinflowandoutflowleadreport', loadChildren: './sales/report/averageinflowandoutflowleadreport/averageinflowandoutflowleadreport.module#AverageinflowandoutflowleadreportModule'},
      {path: 'sourceleadcomparisonreport', loadChildren: './sales/report/sourceleadcomparisonreport/sourceleadcomparisonreport.module#SourceleadcomparisonreportModule'},
      {path: 'salesrevenuereport', loadChildren: './sales/report/salesrevenuereport/salesrevenuereport.module#SalesrevenuereportModule'},
      {path: 'sourceofleadreport', loadChildren: './sales/report/sourceofleadreport/sourceofleadreport.module#SourceofleadreportModule'},
      {path: 'serialnumbertrackingreport', loadChildren: './sales/report/servicedeliverytrackingreport/servicedeliverytrackingreport.module#ServicedeliverytrackingreportModule'},
      {path: 'roiperemployeereport', loadChildren: './sales/report/roiperemployeereport/roiperemployeereport.module#RoiperemployeereportModule'},
      {path: 'paymenthistoryreport', loadChildren: './sales/report/paymenthistoryreport/paymenthistoryreport/paymenthistoryreport.module#PaymenthistoryreportModule'},
      {path: 'monthlyrevenuetargetreport', loadChildren: './sales/report/monthlyrevenuetargetreport/monthlyrevenuetargetreport.module#MonthlyrevenuetargetreportModule'},
      {path: 'adcostperleadreport', loadChildren: './sales/report/adcostperleadreport/adcostperleadreport.module#AdcostperleadreportModule'},
      {path: 'adcostpersaleandperleadreport', loadChildren: './sales/report/adcostpersaleandperleadreport/adcostpersaleandperleadreport.module#AdcostpersaleandperleadreportModule'},
      {path: 'revenuereport', loadChildren: './sales/report/servicedeliveryrevenuereport/servicedeliveryrevenuereport.module#ServicedeliveryrevenuereportModule'},
      {path: 'deliveryreport', loadChildren: './sales/report/servicedeliveryreport/servicedeliveryreport.module#ServicedeliveryreportModule'},
      {path: 'pickupreport', loadChildren: './sales/report/servicepickupreport/servicepickupreport.module#ServicepickupreportModule'},
      {path: 'trainingreport', loadChildren: './sales/report/trainingreport/trainingreport.module#TrainingreportModule'},
      {path: 'averageconversionrationcloseddownreport', loadChildren: './sales/report/averageconversionrationcloseddownreport/averageconversionrationcloseddownreport.module#AverageconversionrationcloseddownreportModule'},
      {path: 'quarterlyactivitytrackingratioreport', loadChildren: './sales/report/quarterlyactivitytrackingratioreport/quarterlyactivitytrackingratioreport.module#QuarterlyactivitytrackingratioreportModule'},
      {path: 'conversionratioreport', loadChildren: './sales/report/conversionratioreport/conversionratioreport.module#ConversionratioreportModule'},
      {path: 'costreport', loadChildren: './sales/report/costreport/costreport.module#CostreportModule'},
      {path: 'customerindustryvisereport', loadChildren: './sales/report/customerindustryreport/customerindustryreport.module#CustomerindustryreportModule'},
      {path: 'invoicereport', loadChildren: './sales/report/invoicereport/invoicereport.module#InvoicereportModule'},
      {path: 'topfastestsellingproductreport', loadChildren: './sales/report/topfastestsellingproductreport/topfastestsellingproductreport.module#TopfastestsellingproductreportModule'},
      {path: 'quotationreport', loadChildren: './sales/report/quotationreport/quotationreport.module#QuotationreportModule'},
      {path: 'leadcommunicationreport', loadChildren: './sales/report/leadcommunicationreport/leadcommunicationreport.module#LeadcommunicationreportModule'},
      {path: 'leadstatisticsreport', loadChildren: './sales/report/leadstatisticsreport/leadstatisticsreport.module#LeadstatisticsreportModule'},
      {path: 'deadleadreport', loadChildren: './sales/report/deadleadreport/deadleadreport.module#DeadleadreportModule'},
      {path: 'salesamounttargetpendingreport', loadChildren: './sales/report/salesamounttargetpendingreport/salesamounttargetpendingreport.module#SalesamounttargetpendingreportModule'},
      {path: 'pendingworkleftreport', loadChildren: './sales/report/pendingworkleftreport/pendingworkleftreport.module#PendingworkleftreportModule'},
      {path: 'customerquotationsentreport', loadChildren: './sales/report/customerquotationsentreport/customerquotationsentreport.module#CustomerquotationsentreportModule'},
      {path: 'customerquotationdeadlinereport',loadChildren: './sales/report/customerquotationdeadlinereport/customerquotationdeadlinereport.module#CustomerquotationdeadlinereportModule'},

      {path: 'projectreport', loadChildren: './projectmanagement/report/projectreport/projectreport.module#ProjectreportModule'},
      {path: 'taskreport', loadChildren: './projectmanagement/report/taskreport/taskreport.module#TaskreportModule'},
      {path: 'issuereport', loadChildren: './projectmanagement/report/issuereport/issuereport.module#IssuereportModule'},
      {path: 'from14', loadChildren: './payroll/compliance/from14/from14.module#From14Module'},
      {path: 'from16', loadChildren: './payroll/compliance/from16/from16.module#From16Module'},
      {path: 'from21', loadChildren: './payroll/compliance/from21/from21.module#From21Module'},
      {path: 'from22', loadChildren: './payroll/compliance/from22/from22.module#From22Module'},
        {path: 'worksites', loadChildren: './payroll/setup/worksite/worksitelist/worksitelist.module#WorksitelistModule'},
        {path: 'insertworksites', loadChildren: './payroll/setup/worksite/insertworksite/insertworksite.module#InsertworksiteModule'},
        {path: 'updateworksites/:id', loadChildren: './payroll/setup/worksite/updateworksite/updateworksite.module#UpdateworksiteModule'},
        {path: 'worksiteview/:id', loadChildren: './payroll/setup/worksite/worksiteview/worksiteview.module#WorksiteviewModule'},
        {path: 'professionaltax', loadChildren: './payroll/setup/professionaltax/professionaltaxlist/professionaltaxlist.module#ProfessionaltaxlistModule'},
        {path: 'insertprofessionaltax', loadChildren: './payroll/setup/professionaltax/insertprofessionaltax/insertprofessionaltax.module#InsertprofessionaltaxModule'},
        {path: 'updateprofessionaltax/:id', loadChildren: './payroll/setup/professionaltax/updateprofessionaltax/updateprofessionaltax.module#UpdateprofessionaltaxModule'},
        {path: 'professionaltaxview/:id', loadChildren: './payroll/setup/professionaltax/professionaltaxview/professionaltaxview.module#ProfessionaltaxviewModule'},
        {path: 'publicholidays', loadChildren: './payroll/setup/publicholiday/publicholidaylist/publicholidaylist.module#PublicholidaylistModule'},
        {path: 'insertpublicholiday', loadChildren: './payroll/setup/publicholiday/insertpublicholiday/insertpublicholiday.module#InsertpublicholidayModule'},
        {path: 'updatepublicholiday/:id', loadChildren: './payroll/setup/publicholiday/updatepublicholiday/updatepublicholiday.module#UpdatepublicholidayModule'},
        {path: 'publicholidayview/:id', loadChildren: './payroll/setup/publicholiday/publicholidayview/publicholidayview.module#PublicholidayviewModule'},
        {path: 'salaryconfigurations', loadChildren: './payroll/setup/salaryconfiguration/salaryconfigurationlist/salaryconfigurationlist.module#SalaryconfigurationlistModule'},
        {path: 'insertsalaryconfiguration', loadChildren: './payroll/setup/salaryconfiguration/insertsalaryconfiguration/insertsalaryconfiguration.module#InsertsalaryconfigurationModule'},
        {path: 'updatesalaryconfiguration/:id', loadChildren: './payroll/setup/salaryconfiguration/updatesalaryconfiguration/updatesalaryconfiguration.module#UpdatesalaryconfigurationModule'},
        {path: 'salaryconfigurationview/:id', loadChildren: './payroll/setup/salaryconfiguration/salaryconfigurationview/salaryconfigurationview.module#SalaryconfigurationviewModule'},
        {path: 'employerconfigurations', loadChildren: './payroll/setup/employerconfiguration/employerconfiguration.module#EmployerconfigurationModule'},
        {path: 'shifts', loadChildren: './payroll/setup/shift/shiftlist/shiftlist.module#ShiftlistModule'},
        {path: 'insertshift', loadChildren: './payroll/setup/shift/insertshift/insertshift.module#InsertshiftModule'},
        {path: 'updateshift/:id', loadChildren: './payroll/setup/shift/updateshift/updateshift.module#UpdateshiftModule'},
        {path: 'shiftview/:id', loadChildren: './payroll/setup/shift/shiftview/shiftview.module#ShiftviewModule'},
        {path: 'weekendoff', loadChildren: './payroll/setup/weekendoff/weekendofflist/weekendofflist.module#WeekendofflistModule'},
        {path: 'insertweekendoff', loadChildren: './payroll/setup/weekendoff/insertweekendoff/insertweekendoff.module#InsertweekendoffModule'},
        {path: 'updateweekendoff/:id', loadChildren: './payroll/setup/weekendoff/updateweekendoff/updateweekendoff.module#UpdateweekendoffModule'},
        {path: 'viewweekendoff/:id', loadChildren: './payroll/setup/weekendoff/weekendoffview/weekendoffview.module#WeekendoffviewModule'},
        {path: 'workingdays', loadChildren: './payroll/setup/workingday/workingdaylist/workingdaylist.module#WorkingdaylistModule'},
        {path: 'insertworkingdays', loadChildren: './payroll/setup/workingday/insertworkingday/insertworkingday.module#InsertworkingdayModule'},
        {path: 'updateworkingdays/:id', loadChildren: './payroll/setup/workingday/updateworkingday/updateworkingday.module#UpdateworkingdayModule'},
        {path: 'workingdaysview/:id', loadChildren: './payroll/setup/workingday/workingdayview/workingdayview.module#WorkingdayviewModule'},
        {path: 'leavetypes', loadChildren: './payroll/setup/leavetype/leavetypelist/leavetypelist.module#LeavetypelistModule'},
        {path: 'insertleavetype', loadChildren: './payroll/setup/leavetype/insertleavetype/insertleavetype.module#InsertleavetypeModule'},
        {path: 'updateleavetype/:id', loadChildren: './payroll/setup/leavetype/updateleavetype/updateleavetype.module#UpdateleavetypeModule'},
        {path: 'leaveconfigurations', loadChildren: './payroll/setup/leaveconfiguration/leaveconfigurationlist/leaveconfigurationlist.module#LeaveonfigurationlistModule'},
        {path: 'insertleaveconfiguration', loadChildren: './payroll/setup/leaveconfiguration/insertleaveconfiguration/insertleaveconfiguration.module#InsertleaveonfigurationModule'},
        {path: 'updateleaveconfiguration/:id', loadChildren: './payroll/setup/leaveconfiguration/updateleaveconfiguration/updateleaveconfiguration.module#UpdateleaveconfigurationModule'},
        {path: 'leaveconfigurationview/:id', loadChildren: './payroll/setup/leaveconfiguration/leaveconfigurationview/leaveconfigurationview.module#LeaveonfigurationviewModule'},
        {path: 'salarysheetreport', loadChildren: './payroll/report/payslipreport/payslipreport.module#PayslipreportModule'},
        {path: 'esicreport', loadChildren: './payroll/report/esicreport/esicreport.module#EsicreportModule'},
        {path: 'ecrreport', loadChildren: './payroll/report/ecrreport/ecrreport.module#EcrreportModule'},
        {path: 'esichallanreport', loadChildren: './payroll/report/esichallanreport/esichallanreport.module#EsichallanreportModule'},
        {path: 'attendancereport', loadChildren: './payroll/report/attendancereport/attendancereport.module#AttendancereportModule'},
        {path: 'bankmacroreport', loadChildren: './payroll/report/bankmacroreport/bankmacroreport.module#BankmacroreportModule'},
        {path: 'leavemanagementreport', loadChildren: './payroll/report/leavemanagementreport/leavemanagementreport.module#LeavemanagementreportModule'},
        {path: 'lwfsettingreport', loadChildren: './payroll/report/lwfsettingsreport/lwfsettingsreport.module#LwfsettingsreportModule'},
        {path: 'pendingloansreport', loadChildren: './payroll/report/pendingloansreport/pendingloansreport.module#PendingloansreportModule'},
        {path: 'travelexpenditurereport', loadChildren: './payroll/report/travelexpenditurereport/travelexpenditurereport.module#TravelexpenditurereportModule'},
        {path: 'monthlytravelexpenditurereport', loadChildren: './payroll/report/monthlytravelexpendtiurereport/monthlytravelexpenditurereport.module#MonthlytravelexpenditurereportModule'},
        {path: 'providentfundreport', loadChildren: './payroll/report/providentfundreport/providentfundreport.module#ProvidentfundreportModule'},
        {path: 'professionaltaxreport', loadChildren: './payroll/report/professionaltaxreport/professionaltaxreport.module#ProfessionaltaxreportModule'},
        {path: 'pfchallanreport', loadChildren: './payroll/report/pfchallanreport/pfchallanreport.module#PfchallanreportModule'},

      {path: 'bookingreport', loadChildren: './businessconsulting/report/bookingreport/bookingreport.module#BookingreportModule'},
      {path: 'enquiryreport', loadChildren: './businessconsulting/report/enquiryreport/enquiryreport.module#EnquiryreportModule'},
      {path: 'monthlyvisitreport', loadChildren: './businessconsulting/report/monthlyvisitreport/monthlyvisitreport.module#MonthlyvisitreportModule'},
      {path: 'monthlyforecastreport', loadChildren: './businessconsulting/report/monthlyforcastreport/monthlyforcastreport.module#MonthlyforcastreportModule'},
      {path: 'registrationreport', loadChildren: './businessconsulting/report/registrationreport/registrationreport.module#RegistrationreportModule'},
      {path: 'enquirymasterreport', loadChildren: './businessconsulting/report/enquirymasterreport/enquirymasterreport.module#EnquirymasterreportModule'},
      {path: 'cummulativetenderreport', loadChildren: './businessconsulting/report/cummulativetenderreport/cummulativetenderreport.module#CummulativetenderreportModule'},
      {path: 'tendersreport', loadChildren: './businessconsulting/report/tenderreport/tenderreport.module#TenderreportModule'},
      {path: 'businesssconsultingprojectreport', loadChildren: './businessconsulting/report/projectreport/projectreport.module#ProjectreportModule'},
      {path: 'prospectsreport', loadChildren: './businessconsulting/report/prospectsreport/prospectsreport.module#ProspectsreportModule'},
      {path: 'purchaseorderreport', loadChildren: './businessconsulting/report/purchaseorderreport/purchaseorderreport.module#PurchaseorderreportModule'},
      {path: 'lostorderreport', loadChildren: './businessconsulting/report/lostorderreport/lostorderreport.module#LostorderreportModule'},
      {path: 'businessplanreport', loadChildren: './businessconsulting/report/businessplanreport/businessplanreport.module#BusinessplanreportModule'},
      {path: 'purchaseorderincentivereport', loadChildren: './businessconsulting/report/purchaseorderincentivereport/purchaseorderincentivereport.module#PurchaseorderincentivereportModule'},
      {path: 'commissionconfiguration', loadChildren: './franchise/setup/commissionconfiguration/commissionconfigurationlist/commissionconfigurationlist.module#CommissionconfigurationlistModule'},
      {path: 'insertcommissionconfigurations', loadChildren: './franchise/setup/commissionconfiguration/insertcommissionconfiguration/insertcommissionconfiguration.module#InsertcommissionconfigurationModule'},
      {path: 'commissionconfigurationview/:id', loadChildren: './franchise/setup/commissionconfiguration/commissionconfigurationview/commissionconfigurationview.module#CommissionconfigurationviewModule'},
      {path: 'updatecommissionconfigurations/:id', loadChildren: './franchise/setup/commissionconfiguration/updatecommissionconfiguration/updatecommissionconfiguration.module#UpdatecommissionconfigurationModule'},

      {path: 'payrollrun', loadChildren: './backoffice/processingcenter/payrollrunlist/payrollrunlist.module#PayrollrunlistModule'},
      {path: 'indiamartleads', loadChildren: './backoffice/processingcenter/indiamartleads/indiamartleads.module#IndiamartleadsModule'},
      {path: 'tradeindialeads', loadChildren: './backoffice/processingcenter/tradeindialeads/tradeindialeads.module#TradeindialeadsModule'},
      {path: 'facebookleads', loadChildren: './backoffice/processingcenter/facebookleads/facebookleads.module#FacebookleadsModule'},
      {path: 'serviceinvoiceprocessing', loadChildren: './backoffice/processingcenter/serviceinvocie/serviceinvocie.module#ServiceinvocieModule'},
      {path: 'outstandingpayments', loadChildren: './backoffice/bulkcommunication/bulkcommunication.module#BulkcommunicationModule'},
      {path: 'communication', loadChildren: './backoffice/communicationcandidaties/communicationcandidaties.module#CommunicationcandidatiesModule'},
      {path: 'processcommissions', loadChildren: './backoffice/processingcenter/processcommission/processcommission.module#ProcesscommissionModule'},
      {path: 'businessplanprocessing', loadChildren: './backoffice/processingcenter/businessplan/businessplan.module#BusinessplanModule'},
      {path: 'rawmaterialstocklimitview/:id', loadChildren: './scm/setup/rawmaterialstocklimit/rawmaterialstocklimitview/rawmaterialstocklimitview.module#RawmaterialstocklimitviewModule'},
      {path: 'rawmaterialstocklimits', loadChildren: './scm/setup/rawmaterialstocklimit/rawmaterialstocklimitlist/rawmaterialstocklimitlist.module#RawmaterialstocklimitlistModule'},
      {path: 'insertrawmaterialstocklimit', loadChildren: './scm/setup/rawmaterialstocklimit/insertrawmaterialstocklimit/insertrawmaterialstocklimit.module#InsertrawmaterialstocklimitModule'},
      {path: 'updaterawmaterialstocklimit/:id', loadChildren: './scm/setup/rawmaterialstocklimit/updaterawmaterialstocklimit/updaterawmaterialstocklimit.module#UpdaterawmaterialstocklimitModule'},
      {path: 'productstocklimitview/:id', loadChildren: './scm/setup/productstocklimit/productstocklimitview/productstocklimitview.module#ProductstocklimitviewModule'},
      {path: 'productstocklimits', loadChildren: './scm/setup/productstocklimit/productstocklimitlist/productstocklimitlist.module#ProductstocklimitlistModule'},
      {path: 'insertproductstocklimit', loadChildren: './scm/setup/productstocklimit/insertproductstocklimit/insertproductstocklimit.module#InsertproductstocklimitModule'},
      {path: 'updateproductstocklimit/:id', loadChildren: './scm/setup/productstocklimit/updateproductstocklimit/updateproductstocklimit.module#UpdateproductstocklimitModule'},
      {path: 'warehouseview/:id', loadChildren: './scm/setup/warehouse/warehouseview/warehouseview.module#WarehouseviewModule'},
      {path: 'warehouses', loadChildren: './scm/setup/warehouse/warehouselist/warehouselist.module#WarehouselistModule'},
      {path: 'insertwarehouse', loadChildren: './scm/setup/warehouse/insertwarehouse/insertwarehouse.module#InsertwarehouseModule'},
      {path: 'updatewarehouse/:id', loadChildren: './scm/setup/warehouse/updatewarehouse/updatewarehouse.module#UpdatewarehouseModule'},
      {path: 'fastestmovingproductreport', loadChildren: './scm/report/fastestmovingproductreport/fastestmovingproductreport.module#FastestmovingproductreportModule'},
      {path: 'othergreatcustomerreport', loadChildren: './scm/report/othergreatcustomerreport/othergreatcustomerreport.module#OthergreatcustomerreportModule'},
      {path: 'productinventorystockinreport', loadChildren: './scm/report/productinventorystockinreport/productinventorystockinreport.module#ProductinventorystockinreportModule'},
      {path: 'productinventorystockoutreport', loadChildren: './scm/report/productinventorystockoutreport/productinventorystockoutreport.module#ProductinventorystockoutreportModule'},
      {path: 'rawmaterialinventorystockinreport', loadChildren: './scm/report/rawmaterialinventorystockinreport/rawmaterialinventorystockinreport.module#RawmaterialinventorystockinreportModule'},
      {path: 'rawmaterialinventorystockoutreport', loadChildren: './scm/report/rawmaterialinventorystockoutreport/rawmaterialinventorystockoutreport.module#RawmaterialinventorystockoutreportModule'},
      {path: 'cashconversioncyclereport', loadChildren: './scm/report/cashconversioncyclereport/cashconversioncyclereport.module#CashconversioncyclereportModule'},
      {path: 'inventoryaccuracyreport', loadChildren: './scm/report/inventoryaccuracyreport/inventoryaccuracyreport.module#InventoryaccuracyreportModule'},
      {path: 'productpurchaseorderreport', loadChildren: './scm/report/productpurchaseorderreport/productpurchaseorderreport.module#ProductpurchaseorderreportModule'},
      {path: 'rawmaterialpurchaseorderreport', loadChildren: './scm/report/rawmaterialpurchaseorderreport/rawmaterialpurchaseorderreport.module#RawmaterialpurchaseorderreportModule'},
      {path: 'purchasedstockinventoryreport', loadChildren: './scm/report/purchasedstockinventoryreport/purchasedstockinventoryreport.module#PurchasedstockinventoryreportModule'},
      {path: 'financialstatementreport', loadChildren: './finance/report/financialstatement/financialstatement.module#FinancialstatementModule'},
      {path: 'expensereport', loadChildren: './finance/report/expensereport/expensereport.module#ExpensereportModule'},
      {path: 'leavepolicies', loadChildren: './humanresources/policies/leavepolicies/leavepolicies.module#LeavepoliciesModule'},
      {path: 'worstperformerreport', loadChildren: './humanresources/report/worstperformerreport/worstperformerreport.module#WorstperformerreportModule'},
      {path: 'orientationduereport', loadChildren: './humanresources/report/orientationduereport/orientationduereport.module#OrientationduereportModule'},
      {path: 'documentationduereport', loadChildren: './humanresources/report/documentationduereport/documentationduereport.module#DocumentationduereportModule'},
      {path: 'peopletakethexamreport', loadChildren: './humanresources/report/peopletakethexamreport/peopletakethexamreport.module#PeopletakethexamreportModule'},
      {path: 'shortlistedcvreport', loadChildren: './humanresources/report/shortlistedcvreport/shortlistedcvreport.module#ShortlistedcvreportModule'},
      {path: 'interviewfootfallreport', loadChildren: './humanresources/report/interviewfootfallreport/interviewfootfallreport.module#InterviewfootfallreportModule'},
      {path: 'closedpositionreport', loadChildren: './humanresources/report/closedpositionreport/closedpositionreport.module#ClosedpositionreportModule'},
      {path: 'orientationtobecompletedreport', loadChildren: './humanresources/report/orientationtobecompletedreport/orientationtobecompletedreport.module#OrientationtobecompletedreportModule'},
      {path: 'validationemployeedoucmentreport', loadChildren: './humanresources/report/validationemployeedoucmentreport/validationemployeedoucmentreport.module#ValidationemployeedoucmentreportModule'},
      {path: 'conversionratiopassedreport', loadChildren: './humanresources/report/conversionratiopassedreport/conversionratiopassedreport.module#ConversionratiopassedreportModule'},
      {path: 'hcvaindexaverageforsalesemployeereport', loadChildren: './humanresources/report/hcvaindexaverageforsalesemployeereport/hcvaindexaverageforsalesemployeereport.module#HcvaindexaverageforsalesemployeereportModule'},
      {path: 'roiemployeereport', loadChildren: './humanresources/report/roiemployeereport/roiemployeereport.module#RoiemployeereportModule'},
      {path: 'hrcompliancebeforeendofmonthreport', loadChildren: './humanresources/report/hrcompliancebeforeendofmonthreport/hrcompliancebeforeendofmonthreport.module#HrcompliancebeforeendofmonthreportModule'},
      {path: 'scheduledinterviewreport', loadChildren: './humanresources/report/scheduledinterviewsreport/scheduledinterviewsreport.module#ScheduledinterviewsreportModule'},
      {path: 'jobopeningsreport', loadChildren: './humanresources/report/jobopeningsreport/jobopeningsreport.module#JobopeningsreportModule'},

      {path: 'warrantyreport', loadChildren: './sales/report/warrantyreport/warrantyreport.module#WarrantyreportModule'},
      {path: 'productstockavailability', loadChildren: './scm/report/stockavailabilityreport/stockavailabilityreport.module#StockavailabilityreportModule'},
      {path: 'rawmaterialstockavailability', loadChildren: './scm/report/rawmaterialstockavailability/rawmaterialstockavailability.module#RawmaterialstockavailabilityModule'},
      {path: 'posrevenuereport', loadChildren: './pos/report/revenuereport/revenuereport.module#RevenuereportModule'},
      {path: 'leaveallocation', loadChildren: './backoffice/processingcenter/leaveallocation/leaveallocation.module#LeaveallocationModule'},
    ]
  },
  {
    path: '',
    component: SidemenulayoutComponent,
    loadChildren: './sidemenu.module#SideMenuModule',
  },
  {
      path: '',
      component: AccountComponent,
      children: [
          {path: 'profile', loadChildren: './account/profile/profile.module#ProfileModule'},
          {path: 'changepassword', loadChildren: './account/changepassword/changepassword.module#ChangepasswordModule'},
          {path: 'users', loadChildren: './account/users/users.module#UsersModule'},
      ]
  },
  {path: '**', component: PagenotfoundComponent},
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
